import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Table, message } from 'antd';
import { useSelector } from "react-redux";

import { fontSize, MySubtitleH4 } from '../../styled/publicTag';
import { Input , Button } from 'antd';

import { IconCheck } from '../../components/Icon';

import { PlusOutlined, CheckCircleFilled, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { getAllStaffPerm, deleteStaffPerm, getPdsID, getStaff } from '../../services/api';

import Title from '../../components/Custom/Title';
import Wrapper from '../../components/Custom/Wrapper';
import CustomTable from '../../components/antdCustom/CustomTable';
import CustomModal from '../../components/antdCustom/CustomModal';

import {
  IconTrash,
  IconPen
} from '../../components/Icon'


import ModalContainer from './ModalContainer';

const SystemLogin = () => {

  const innerWidth = window.innerWidth;

  const [s_tableData, set_s_tableData] = useState([]);
  const [s_showModal, set_s_showModal] = useState(false);  // 是否開啟 dialog
  const [s_staffData, set_s_staffData] = useState({});
  const [tableLoading, setTableLoading] = useState(false);  // api尚未回來時讓ui處於加載狀態

  const [s_searchValue, set_s_searchValue] = useState('');    // 要搜尋的文字

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  // gz_pds 改成只有superman可以做CRUD
  const r_userState = useSelector((allReducers) => allReducers.userState);

  const createStaff = () => {
    set_s_showModal(true);
  }
  // 修改員工基本資料
  const editStaff = staffData => {
    set_s_showModal(true);
    set_s_staffData(staffData);
  }
  // 刪除戰情登錄權限
  const removeStaff = rowData => {
    Modal.confirm({
      title: '權限刪除確認',
      icon: <ExclamationCircleOutlined style={{color: 'red'}} />,
      content: '請先確認要刪除的登錄權限無誤，再進行刪除',
      okText: '確定',
      onOk: async () => {
        const res = await deleteStaffPerm(rowData);
        res.status ? message.success(res.msg) : message.error(res.msg);
        //刪除後要更新資料
        call_getAllStaffPerm();
      },
      cancelText: '取消',
    });
    
  }
  const tableColumns = [
    {
      title: '員工帳號',
      dataIndex: 'account',
      key: 'account',
      align: 'center',
      //width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '員工姓名',
      dataIndex: 'peopleNM',
      key: 'peopleNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100*1.6}px`
    },
    {
      title: '使用行動APP',
      dataIndex: 'setAPP',
      key: 'setAPP',
      width: innerWidth <= 1440 ? `${130}px` : `${130}px`,
      render: (text, rowData, index) => {
        const key = rowData.setAPP;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '接收自動報表',
      dataIndex: 'setautorpt',
      key: 'setautorpt',
      width: innerWidth <= 1440 ? `${130}px` : `${130}px`,
      render: (text, rowData, index) => {
        const key = rowData.setautorpt;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '基本資料維護',
      dataIndex: 'setBDM',
      key:'setBDM',
      width: innerWidth <= 1440 ? `${130}px` : `${130}px`,
      render: (text, rowData, index) => {
        const key = rowData.setBDM;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '系統資料維護',
      dataIndex: 'setSDM',
      key: 'setSDM',
      width: innerWidth <= 1440 ? `${130}px` : `${130}px`,
      render: (text, rowData, index) => {
        const key = rowData.setSDM;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '品保異常判責',
      dataIndex: 'setABNQ',
      key: 'setABNQ',
      width: innerWidth <= 1440 ? `${130}px` : `${130}px`,
      render: (text, rowData, index) => {
        const key = rowData.setABNQ;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '戰情系統',
      dataIndex: 'setDBS',
      key:'setDBS',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${130}px`,
      render: (text, rowData, index) => {
        const key = rowData.setDBS;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '生管派工',
      dataIndex: 'setAPS',
      key: 'setAPS',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render: (text, rowData, index) => {
        const key = rowData.setAPS;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '庫儲管理',
      dataIndex: 'setWMS',
      key: 'setWMS',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render: (text, rowData, index) => {
        const key = rowData.setWMS;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '訂單管理',
      dataIndex: 'setOPS',
      key: 'setOPS',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render: (text, rowData, index) => {
        const key = rowData.setOPS;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '管理推播',
      dataIndex: 'setMNS',
      key: 'setMNS',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render: (text, rowData, index) => {
        const key = rowData.setMNS;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    
    {
      title: '廠務管理',
      dataIndex: 'setFMS',
      key: 'setFMS',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render: (text, rowData, index) => {
        const key = rowData.setFMS;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '總務管理',
      dataIndex: 'setGMS',
      key: 'setGMS',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
      render: (text, rowData, index) => {
        const key = rowData.setGMS;
        return {
          props: {
            style: { backgroundColor: key === '2' ? '#dbd9d9' : '' }
          },
          children: (
            <div className="checkIcon">
             {key === '0' ? <CloseCircleOutlined style={{color: 'gray'}} /> : key === '1' ? <CheckCircleFilled className='successIcon' /> : null}
            </div>
          )
        };
      }
    },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      render: (text, rowData, index) => (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <IconPen className='icon' onClick={() => editStaff(rowData)} />
          <IconTrash className='icon' onClick={() => removeStaff(rowData)} />
        </div>
      )
    }
  ]

  const call_getAllStaffPerm = async () => {
    try {
      setTableLoading(true);
      const res = s_searchValue === '' ? await getAllStaffPerm() : await getPdsID(s_searchValue);
      console.log('全部權限資料 = ', res.data)
      if(res.status && res.data) {
        const tmpData = res.data.map((item, index) => {
        return {
          ...item,
          key: index
        }
      });
      //console.log('所有人的權限資料 = ', tmpData)
      set_s_tableData(tmpData);
      }
      
      setTableLoading(false);
    } catch (e) {
      console.log('---------- 戰情登錄權限 api getAllStaffPerm error ----------');
      console.log(e)
    }
  }
  // 取得所有人的權限資料
  useEffect(() => {
    
    call_getAllStaffPerm();
    if(s_showModal === false) {
      set_s_staffData({})
    }
  }, [s_showModal])

  // 搜尋員工權限資料
  const onSearch = (value, event) => {
    
    const call_getPdsID = async (value) => {
      
      if(s_searchValue === '') return;

      const res = await getPdsID(s_searchValue);
      
      if(res.status) {
        const tmp = res.data.map((item, index) => {
          return {...item, key: index}
        })
        set_s_tableData(tmp);
        message.success(res.msg);
      } else {
        message.error(res.msg);
      }

    }

    call_getPdsID(s_searchValue);
  }

  const handleChange = async value => {
    set_s_searchValue(value)
    if(value === '') {
      try {
        setTableLoading(true);
        const res = await getAllStaffPerm();
        console.log('全部權限資料 = ', res.data)
        if(res.status && res.data) {
          const tmpData = res.data.map((item, index) => {
          return {
            ...item,
            key: index
          }
        });
        //console.log('所有人的權限資料 = ', tmpData)
        set_s_tableData(tmpData);
        }
        
        setTableLoading(false);
      } catch (e) {
        console.log('---------- 戰情登錄權限 api getAllStaffPerm error ----------');
        console.log(e)
      }
    }
  }

  return (
    <>
      <Title 
        title='戰情登錄權限'
        placeholder='請輸入欲查詢員工資料'
        onSearch={onSearch}
        handleChange={handleChange}
        handleClick={createStaff}
      />
      <CustomTable 
        scroll={{ x: 'max-content',y: window.innerHeight - 60 - 45 - 220 }}
        loading={tableLoading}
        columns={tableColumns}
        dataSource={s_tableData}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setPageInfo({
              ...pageInfo,
              pageSize: size
            })
            // setAbcBoardConfig({
            //   ...abcBoardConfig,
            //   page: current,
            //   pageSize: size,
            // });
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />
      {s_showModal ? (
        <CustomModal 
          width='50%' 
          title={Object.keys(s_staffData).length > 0 ? "修改戰情登錄權限" : "新建戰情登錄權限"} 
          visible={s_showModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_s_showModal(false)}
          //afterClose={() => set_s_staffData({})}
        >
          <ModalContainer set_s_showModal={set_s_showModal} s_staffData={s_staffData} />
        </CustomModal>
      ) : null}
      
    </>
  )
};

export default SystemLogin;