import React, {useState, useEffect} from 'react';
import { Input, Modal } from 'antd';

import CustomTable from '../../../../components/antdCustom/CustomTable';
import CustomModal from '../../../../components/antdCustom/CustomModal';

import ModalContainer from './ModalContainer';

import {getAllPlanInTb} from '../../../../services/WMS/PlainIn';
import {IconPen} from '../../../../components/Icon/newicon';

const opcodeData = [
  {
    'key': '4110',
    'tabName': '生產入庫'
  },
  {
    'key': '4120',
    'tabName': '採購入庫'
  },
  {
    'key': '4130',
    'tabName': '其他入庫'
  }
];

const innerWidth = window.innerWidth;

const TB = ({s_record, call_getAllPlanInHd}) => {

  const [ s_tableData, set_s_tableData ] = useState([]);

  const [ is_showModal, set_is_showModal ] = useState(false);

  const [ s_editData, set_s_editData ] = useState({});

  const tableColumns = [
    {
      title: '過帳型態',
      dataIndex: 'setidm',
      key: 'setidm',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${250}px`,
      render: (text) => {
        return text === 'S' ? '靜態' : '動態'
      }
    },
    {
      title: '料號',
      dataIndex: 'PN',
      key: 'PN',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${250}px`,
    },
    {
      title: '動態數量',
      dataIndex: 'dqty',
      key: 'dqty',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${200}px`,
      render: (text) => {
        return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    },
    {
      title: '靜態數量',
      dataIndex: 'sqty',
      key: 'sqty',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${200}px`,
      render: (text) => {
        return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    },
    {
      title: '總數量',
      dataIndex: 'tqty',
      key: 'tqty',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${200}px`,
      render: (text) => {
        return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
    },
    {
      title: '異動歷程',
      dataIndex: 'lifeF',
      key: 'lifeF',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${250}px`,
      render: (text, rowData, index) => {
        const keyMap = {
          "0": '開單發佈',
          "1": '入庫規劃',
          "H": '料單入庫規劃',
          "4": "出庫規劃",
          "T": "已執行更新"
        };
        return keyMap[text]
      }
    },
    {
      title: '作業時間',
      dataIndex: 'lifeFTstr',
      key: 'lifeFTstr',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${250}px`
    },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      align: 'center',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      render: (text, rowData, index) => {

        const cursor = rowData.setidm === 'S' ? 'pointer' : 'not-allowed';
        const color = rowData.setidm === 'S' ? 'rgb(97, 87, 97)' : '#c9c3c3';

        return (
          <div 
            style={{display: 'flex',justifyContent: 'center', fontSize: '24px', cursor: cursor, color: color}}
            onClick={rowData.setidm === 'S' ? () => editData(rowData) : null}
          >
            <IconPen />
          </div>
        )
      }
    },
  ];

  const editData = rowData => {
    set_is_showModal(true);
    set_s_editData(rowData);
  }

  useEffect(() => {
    const call_getAllPlanInTb = async () => {
      const res = await getAllPlanInTb(s_record);

      res.status === true ? set_s_tableData(res.data) : set_s_tableData([])
    };

    call_getAllPlanInTb();

    // 錄入靜態資料後要重抓表頭API
    call_getAllPlanInHd();

  }, [s_record, is_showModal]);

  return (
    <>
    <CustomTable 
      scroll={{ x: "max-content",y: window.innerHeight - 64 - 60 - 70 - 50 - 120 }}
      columns={tableColumns}
      dataSource={s_tableData.length > 0 ? s_tableData.map((item, index) => {
        return {...item, key: index}
      }) : []}
      pagination={false}
    /> 

      {is_showModal === true ? (
        <CustomModal 
          width={innerWidth > 1440 ? '20%' : '20%'} 
          title="錄入靜態數量"
          visible={is_showModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          centered
          onCancel={() => {
            set_is_showModal(false);
            set_s_editData({});
          }}
        >
          <ModalContainer 
            set_is_showModal={set_is_showModal}
            s_editData={s_editData}
            set_s_editData={set_s_editData}
          />
        </CustomModal>
    ) : null}
    </>
  )
};

export default TB;