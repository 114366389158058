import React, { useState } from 'react';
import CustomTable from '../../../../components/antdCustom/CustomTable';
import { MySubtitleH4 } from '../../../../styled/publicTag';

const HubTable = ({s_selectHub}) => {

  console.log('s_selectHub = ', s_selectHub)

  const innerWidth = window.innerWidth;

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10
  });

  const tableColumns = [
    {
      title: '工單號',
      dataIndex: 'woN',
      key: 'woN',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${200}px`
    },
    {
      title: '站別',
      dataIndex: 'wsID',
      key: 'wsID',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${100}px`
    },
    {
      title: '線別',
      dataIndex: 'wlID',
      key: 'wlID',
      align: 'center',
      width: innerWidth <= 1440 ? `${250}px` : `${100}px`
    },
    {
      title: '料號',
      dataIndex: 'PN',
      key: 'PN',
      align: 'center',
      width: innerWidth <= 1440 ? `${150}px` : `${200}px`
    },
    {
      title: '入庫數量',
      dataIndex: 'inqty',
      key: 'inqty',
      align: 'center',
      width: innerWidth <= 1440 ? `${250}px` : `${100}px`,
      render: (text, rowData, index) => {
        return {
          children: (
            <div>
              {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          )
        }
      }
    },
    {
      title: '註記',
      dataIndex: 'inmsg',
      key: 'inmsg',
      align: 'center',
      width: innerWidth <= 1440 ? `${250}px` : `${300}px`,
    },
    {
      title: '過站時間',
      dataIndex: 'lifeFTstr',
      key: 'lifeFTstr',
      align: 'center',
      width: innerWidth <= 1440 ? `${250}px` : `${150}px`
    },
  ]

  return (
    <CustomTable 
      scroll={{ x: "max-content",y: window.innerHeight/3 }}
      columns={tableColumns}
      dataSource={s_selectHub.length > 0 && s_selectHub.map((item, index) => {
        return {
          key: index,
          ...item
        }
      })}
      pagination={false}
      // pagination={{
      //   defaultCurrent: 1,
      //   total: pageInfo.totalItems,
      //   // current: abcBoardConfig.page,
      //   showSizeChanger: true,
      //   pageSize: pageInfo.pageSize,
      //   pageSizeOptions: ["10", "20", "30"],
      //   onChange: (current, size) => {
      //     setPageInfo({
      //       ...pageInfo,
      //       pageSize: size
      //     })
      //   },
      //   showTotal: (total, range) => (
      //     <MySubtitleH4>
      //       共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
      //     </MySubtitleH4>
      //   ),
      // }}
    />
  )
};
export default HubTable;
