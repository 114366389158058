export const antdLight = {
  "@primary-color": "#7ab1f4", // 主題色
  "@text-color": "#6C6C6C", // 文字顏色 fade(@black, 85%)
  "@component-background": "#FFF", // ex: 表格內容背景色、navbar背景色
  "@background-color-light": "#FFF",
  "@table-header-sort-bg": "#d9e7e4",
  //"@disabled-color": 'red',
  "@select-item-selected-bg": "@primary-1",
  "@table-row-hover-bg": "rgba(253, 255, 203, .5)", // #EFF8F7  rgba(240, 249, 254, .7)  #fdffcc
  "@table-header-color": "#6C6C6C", // @heading-color
  "@table-header-bg": "rgb(192, 220, 240)", // @primary-1
  "@table-bg": "#f5f5f5",
  "@table-body-sort-bg": "#fafafa",
  "@layout-body-background": "#FFF", //#F0F2F5
  "@layout-header-background": "#fff",
  "@item-hover-bg": "#f5f5f5",
  "@layout-sider-background": "@layout-header-background", //
  "@modal-heading-color": "@primary-color", // Modal style
  "@modal-close-color": "@primary-color", // Modal style
  "@collapse-header-bg": "#F0F2F5",
  "@collapse-header-color": "@primary-color",
  "@message-notice-content-bg": "#FFF2CC",
  "@label-color": "rgb(108, 108, 108)",
  "@modal-header-bg": "#FFF",
  "@menu-item-color": "#6C6C6C",
  "@icon-color": "red"
};
export const antdDark = {
  "@primary-color": "#5995BC",
  "@text-color": "#ffffff",
  "@component-background": "rgb(58, 71, 78)",
  "@table-row-hover-bg": "#7d7d67",
  "@disabled-color": "#FFF",
  "@table-bg": "#424242",
  "@disabled-color-dark": "red",
  "@background-color-light": "#2b587b",
  "@background-color-base": "rgba(20, 77, 107 , 0.5)",
  "@table-header-sort-bg": "@background-color-base",
  "@table-border-color": "rgba(81, 81, 81, 1)",
  "@select-item-selected-bg": "rgba(0, 150, 136, 0.4)",
  "@item-hover-bg": "rgba(0, 150, 136, 0.1)",
  "@table-body-sort-bg": "#526772",
  "@table-header-color": "#ffffff",
  "@table-header-bg": "rgb(34, 96, 139)",
  "@layout-body-background": "rgba(0, 0, 0, 0.9)",
  "@layout-header-background": "#212121",
  "@layout-sider-background": "#141414 ",
  "@menu-dark-bg": "#141414 ",
  "@menu-dark-submenu-bg": "#141414 ",
  "@modal-heading-color": "@primary-color", // Modal style
  "@modal-close-color": "@primary-color", // Modal style
  "@message-notice-content-bg": "#FFF2CC",
  "@label-color": "#FFF",
  "@modal-header-bg": "#424242",
  "@input-disabled-color": "#FFF",
  "@menu-item-color": "#FFF",
  "@icon-color": "red"
};
export const change_antd_theme = (color) => {
  window.less
    .modifyVars(color)
    .then(() => {})
    .catch((error) => {
      console.log("更換主題失敗", error);
    });
};
