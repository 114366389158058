export const setInitData = (data) => {
  return {
    type: 'SET_AUTH_PAGE_DATA',
    payload:data
  }
}

export const changeMenuStatus = (status) => {
  return {
    type: 'CHANGE_MENU_STATUS',
    payload: !status
  }
}

export const changeNavBarInlineStatus = (status) => {
  return {
    type: 'CHANGE_NAVBAR_INLINE_STATUS',
    payload: !status
  }
}

export const changeTheme = (theme) => {
  return {
    type: 'CHANGE_THEME',
    payload: theme
  }
}

export const setDept = (data) => {
  return {
    type: 'SET_DEPT',
    payload: data
  }
}

export const setPost = (data) => {
  return {
    type: 'SET_POST',
    payload: data
  }
}

export const setuserStateReducer = (data) => {
  return {
    type: 'SET_USERSTATE',
    payload: data
  }
}