import styled from "styled-components";
//import { ReactComponent as Dashboard } from "../../assets/icon/dashboard.svg";
import { ReactComponent as Abcboard } from "../../assets/icon/icon-abcboard-2.svg";
import { ReactComponent as Overview } from "../../assets/icon/icon-overview.svg";
import { ReactComponent as LinesOverview } from "../../assets/icon/icon-lines-overview.svg";
import { ReactComponent as LineDetails } from "../../assets/icon/icon-line-details.svg";
//import { ReactComponent as Information } from "../../assets/icon/information.svg";
import { ReactComponent as WorkOrders } from "../../assets/icon/work-orders.svg";
import { ReactComponent as SystemData } from "../../assets/icon/system-data.svg";
import { ReactComponent as ThemeSvg } from "../../assets/icon/theme.svg";
import { ReactComponent as Lock } from "../../assets/icon/lock.svg";
import { ReactComponent as Unlock } from "../../assets/icon/unlock.svg";
import { ReactComponent as Trash } from "../../assets/icon/trash.svg";
import { ReactComponent as Pen } from "../../assets/icon/pen.svg";
import { ReactComponent as Users } from "../../assets/icon/icon-users.svg";
import { ReactComponent as SystemLogin } from "../../assets/icon/icon-system-login.svg";
import { ReactComponent as Permissions } from "../../assets/icon/icon-wos-permissions.svg";
import { ReactComponent as ReportSetting } from "../../assets/icon/icon-report-setting.svg";
import { ReactComponent as BasicData } from "../../assets/icon/icon-basic-data.svg";
import { ReactComponent as Information } from "../../assets/icon/icon-information.svg";
import { ReactComponent as BasicProduct } from "../../assets/icon/icon-basic-product-info.svg";
import { ReactComponent as ProductWpc } from "../../assets/icon/icon-product-wpc.svg";
import { ReactComponent as PsData } from "../../assets/icon/icon-ps-data.svg";
import { ReactComponent as Station } from "../../assets/icon/icon-station.svg";
import { ReactComponent as Line } from "../../assets/icon/icon-line.svg";
import { ReactComponent as Mould } from "../../assets/icon/icon-mould.svg";
import { ReactComponent as WsReport } from "../../assets/icon/icon-ws-report.svg";
import { ReactComponent as WsResume } from "../../assets/icon/icon-ws-resume.svg";
import { ReactComponent as Wsgo } from "../../assets/icon/icon-ws-go.svg";
import { ReactComponent as Dashboard } from "../../assets/icon/icon-dashboard.svg";
import { ReactComponent as Card } from "../../assets/icon/icon-card.svg";
import { ReactComponent as Inproduction } from "../../assets/icon/icon-inproduction.svg";
import { ReactComponent as ApsManager } from "../../assets/icon/icon-aps-manager.svg";
import { ReactComponent as Won } from "../../assets/icon/icon-won.svg";
import { ReactComponent as Hdc } from "../../assets/icon/icon-hdc.svg";
import { ReactComponent as Mhc } from "../../assets/icon/icon-mhc.svg";
import { ReactComponent as Okc } from "../../assets/icon/icon-okc.svg";
import { ReactComponent as OpenT } from "../../assets/icon/icon-openT.svg";
import { ReactComponent as PlanHead } from "../../assets/icon/icon-planHead.svg";
import { ReactComponent as PlanQty } from "../../assets/icon/icon-planQty.svg";
import { ReactComponent as Pn } from "../../assets/icon/icon-pn.svg";
import { ReactComponent as Ws } from "../../assets/icon/icon-ws.svg";
import { ReactComponent as Wl } from "../../assets/icon/icon-wl.svg";
import { ReactComponent as User } from "../../assets/icon/icon-user.svg";
import { ReactComponent as StandbyTime } from "../../assets/icon/icon-standbyTime.svg";
import { ReactComponent as ProccessTime } from "../../assets/icon/icon-proccessTime.svg";
import { ReactComponent as Sun } from "../../assets/icon/icon-sun.svg";
import { ReactComponent as Moon } from "../../assets/icon/icon-moon.svg";
import { ReactComponent as PdtNM } from "../../assets/icon/icon-pdtnm.svg";
import { ReactComponent as NgC } from "../../assets/icon/icon-ngc.svg";
import { ReactComponent as Act } from "../../assets/icon/icon-act.svg";
import { ReactComponent as Ppe } from "../../assets/icon/icon-ppe.svg";
import { ReactComponent as Tar } from "../../assets/icon/icon-tar.svg";
import { ReactComponent as Qty } from "../../assets/icon/icon-qty.svg";

import { ReactComponent as Building } from "../../assets/icon/Building.svg";

import { CheckCircleFilled } from "@ant-design/icons";

const iconBox = {
  width: "36px",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
};

// navBar 上子分類的 icon style
const subMenuIconStyle = {
  width: "100%",
  marginRight: "5px",
};

const Span = styled.span`
  color: ${({theme}) => theme.menuIcon};
`

const MenuIconModal = ({ style, Icon, onClick }) => {
  //console.log('onClick = ', onClick)
  return (
    <Span style={{ ...iconBox, ...style }} onClick={onClick}>
      {Icon}
    </Span>
  );
};

const IconModal = ({ style, Icon, onClick }) => {
  //console.log('onClick = ', onClick)
  return (
    <span style={{ ...iconBox, ...style }} onClick={onClick}>
      {Icon}
    </span>
  );
};

// 顯示勾勾的icon
export const IconCheck = ({ style }) => {
  const newStyle = {
    fontSize: "20px",
    //color:
  };

  return <CheckCircleFilled style={newStyle} />;
};

// 生產報表查詢
export const IconUsers = ({ style, onClick }) => {
  return (
    <MenuIconModal
      Icon={<Users />}
      style={{ ...subMenuIconStyle, ...style }}
      onClick={onClick}
    />
  );
};

// 生產報表查詢
export const IconApsManager = ({ style, onClick }) => {
  return (
    <MenuIconModal
      Icon={<ApsManager />}
      style={{ ...subMenuIconStyle, ...style }}
      onClick={onClick}
    />
  );
};

// 生產報表查詢
export const IconInproduction = ({ style, onClick }) => {
  return (
    <MenuIconModal
      Icon={<Inproduction />}
      style={{ ...subMenuIconStyle, ...style }}
      onClick={onClick}
    />
  );
};
// 數位生產圖標
export const IconCard = ({ style, onClick }) => {
  return (
    <MenuIconModal
      Icon={<Card />}
      style={{ ...subMenuIconStyle, ...style }}
      onClick={onClick}
    />
  );
};

// 生產履歷查詢
export const IconWsResume = ({ style, onClick }) => {
  return (
    <MenuIconModal
      Icon={<WsResume />}
      style={{ ...subMenuIconStyle, ...style }}
      onClick={onClick}
    />
  );
};

// 生產履歷查詢
export const IconWsgo = ({ style, onClick }) => {
  return (
    <IconModal
      Icon={<Wsgo />}
      style={{ ...subMenuIconStyle, ...style }}
      onClick={onClick}
    />
  );
};

// 員工基本資料
export const IconWsReport = ({ style, onClick }) => {
  return (
    <MenuIconModal
      Icon={<WsReport />}
      style={{ ...subMenuIconStyle, ...style }}
      onClick={onClick}
    />
  );
};
// 戰情登錄權限
export const IconSystemLogin = ({ style, onClick }) => {
  return (
    <MenuIconModal
      Icon={<SystemLogin />}
      style={{ ...subMenuIconStyle, ...style }}
      onClick={onClick}
    />
  );
};

// 報工作業權限
export const IconPermissions = ({ style, onClick }) => {
  return (
    <MenuIconModal
      Icon={<Permissions />}
      style={{ ...subMenuIconStyle, ...style }}
      onClick={onClick}
    />
  );
};

// 報表週期設定
export const IconReportSetting = ({ style, onClick }) => {
  return (
    <IconModal
      Icon={<ReportSetting />}
      style={{ ...subMenuIconStyle, ...style }}
      onClick={onClick}
    />
  );
};

// 基本資料維護
export const IconBasicData = ({ style, onClick }) => {
  return (
    <MenuIconModal
      Icon={<BasicData />}
      style={{ ...subMenuIconStyle, ...style }}
      onClick={onClick}
    />
  );
};

// 產品基本資料
export const IconBasicProduct = ({ style, onClick }) => {
  return (
    <MenuIconModal
      Icon={<BasicProduct />}
      style={{ ...subMenuIconStyle, ...style }}
      onClick={onClick}
    />
  );
};

// 產品途程資料
export const IconProductWpc = ({ style, onClick }) => {
  return (
    <MenuIconModal
      Icon={<ProductWpc />}
      style={{ ...subMenuIconStyle, ...style }}
      onClick={onClick}
    />
  );
};

// 車間
export const IconPsData = ({ style, onClick }) => {
  return (
    <MenuIconModal
      Icon={<PsData />}
      style={{ ...subMenuIconStyle, ...style }}
      onClick={onClick}
    />
  );
};

// 工站
export const IconStation = ({ style, onClick }) => {
  return (
    <MenuIconModal
      Icon={<Station />}
      style={{ ...subMenuIconStyle, ...style }}
      onClick={onClick}
    />
  );
};

// 工線
export const IconLine = ({ style, onClick }) => {
  return (
    <MenuIconModal
      Icon={<Line />}
      style={{ ...subMenuIconStyle, ...style }}
      onClick={onClick}
    />
  );
};

// 工線
export const IconMould = ({ style, onClick }) => {
  return (
    <MenuIconModal
      Icon={<Mould />}
      style={{ ...subMenuIconStyle, ...style }}
      onClick={onClick}
    />
  );
};

export const IconBuilding = ({ style, onClick }) => {
  return <IconModal style={style} Icon={<Building />} onClick={onClick} />;
};

export const IconDashboard = ({ style, onClick }) => {
  return <MenuIconModal style={style} Icon={<Dashboard />} onClick={onClick} />;
};

export const IconAbcboard = ({ style, onClick }) => {
  const newstyle = {
    ...style,
    marginRight: "10px",
  };
  return <MenuIconModal style={style} Icon={<Abcboard />} onClick={onClick} />;
};

export const IconOverview = ({ style, onClick }) => {
  return <IconModal style={style} Icon={<Overview />} onClick={onClick} />;
};

export const IconLinesOverview = ({ style, onClick }) => {
  return <MenuIconModal style={style} Icon={<LinesOverview />} onClick={onClick} />;
};

export const IconLineDetails = ({ style, onClick }) => {
  return <MenuIconModal style={style} Icon={<LineDetails />} onClick={onClick} />;
};

export const IconInformation = ({ style, onClick }) => {
  return <MenuIconModal style={style} Icon={<Information />} onClick={onClick} />;
};

export const IconWorkOrders = ({ style, onClick }) => {
  return <IconModal style={style} Icon={<WorkOrders />} onClick={onClick} />;
};

export const IconSystemData = ({ style, onClick }) => {
  return <MenuIconModal style={style} Icon={<SystemData />} onClick={onClick} />;
};

export const IconTheme = ({ style, onClick }) => {
  return <IconModal style={style} Icon={<ThemeSvg />} onClick={onClick} />;
};

export const IconLock = ({ style, onClick }) => {
  const newstyle = {
    width: "30px",
    hight: "30px",
    ...style,
  };
  return <IconModal style={newstyle} Icon={<Lock />} onClick={onClick} />;
};

export const IconUnlock = ({ style, onClick }) => {
  const newstyle = {
    ...style,
    width: "30px",
    hight: "30px",
  };
  return <IconModal style={newstyle} Icon={<Unlock />} onClick={onClick} />;
};

export const IconTrash = ({ style, onClick }) => {
  const newstyle = {
    ...style,
    width: "30px",
    hight: "30px",
  };
  return <IconModal style={newstyle} Icon={<Trash />} onClick={onClick} />;
};

export const IconPen = ({ style, onClick }) => {
  const newstyle = {
    ...style,
    width: "30px",
    hight: "30px",
  };
  return <IconModal style={newstyle} Icon={<Pen />} onClick={onClick} />;
};

export const IconWon = ({ style, onClick }) => {
  return (
    <IconModal
      style={{ ...subMenuIconStyle, ...style }}
      Icon={<Won />}
      onClick={onClick}
    />
  );
};

export const IconHdc = ({ style, onClick }) => {
  return (
    <IconModal
      style={{ ...subMenuIconStyle, ...style }}
      Icon={<Hdc />}
      onClick={onClick}
    />
  );
};

export const IconMhc = ({ style, onClick }) => {
  return (
    <IconModal
      style={{ ...subMenuIconStyle, ...style }}
      Icon={<Mhc />}
      onClick={onClick}
    />
  );
};

export const IconOkc = ({ style, onClick }) => {
  return (
    <IconModal
      style={{ ...subMenuIconStyle, ...style }}
      Icon={<Okc />}
      onClick={onClick}
    />
  );
};

export const IconOpenT = ({ style, onClick }) => {
  return (
    <IconModal
      style={{ ...subMenuIconStyle, ...style }}
      Icon={<OpenT />}
      onClick={onClick}
    />
  );
};

export const IconPlanHead = ({ style, onClick }) => {
  return (
    <IconModal
      style={{ ...subMenuIconStyle, ...style }}
      Icon={<PlanHead />}
      onClick={onClick}
    />
  );
};

export const IconPlanQty = ({ style, onClick }) => {
  return (
    <IconModal
      style={{ ...subMenuIconStyle, ...style }}
      Icon={<PlanQty />}
      onClick={onClick}
    />
  );
};

export const IconPn = ({ style, onClick }) => {
  return (
    <IconModal
      style={{ ...subMenuIconStyle, ...style }}
      Icon={<Pn />}
      onClick={onClick}
    />
  );
};

export const IconWs = ({ style, onClick }) => {
  return (
    <IconModal
      style={{ ...subMenuIconStyle, ...style }}
      Icon={<Ws />}
      onClick={onClick}
    />
  );
};

export const IconWl = ({ style, onClick }) => {
  return (
    <IconModal
      style={{ ...subMenuIconStyle, ...style }}
      Icon={<Wl />}
      onClick={onClick}
    />
  );
};

export const IconUser = ({ style, onClick }) => {
  return (
    <IconModal
      style={{ ...subMenuIconStyle, ...style }}
      Icon={<User />}
      onClick={onClick}
    />
  );
};

export const IconStandbyTime = ({ style, onClick }) => {
  return (
    <IconModal
      style={{ ...subMenuIconStyle, ...style }}
      Icon={<StandbyTime />}
      onClick={onClick}
    />
  );
};

export const IconProccessTime = ({ style, onClick }) => {
  return (
    <IconModal
      style={{ ...subMenuIconStyle, ...style }}
      Icon={<ProccessTime />}
      onClick={onClick}
    />
  );
};

export const IconSun = ({ style, onClick }) => {
  return (
    <IconModal
      style={{ ...subMenuIconStyle, ...style }}
      Icon={<Sun />}
      onClick={onClick}
    />
  );
};

export const IconMoon = ({ style, onClick }) => {
  return (
    <IconModal
      style={{ ...subMenuIconStyle, ...style }}
      Icon={<Moon />}
      onClick={onClick}
    />
  );
};

export const IconPdtNM = ({ style, onClick }) => {
  return (
    <IconModal
      style={{ ...subMenuIconStyle, ...style }}
      Icon={<PdtNM />}
      onClick={onClick}
    />
  );
};

export const IconNgC = ({ style, onClick }) => {
  return (
    <IconModal
      style={{ ...subMenuIconStyle, ...style }}
      Icon={<NgC />}
      onClick={onClick}
    />
  );
};

export const IconAct = ({ style, onClick }) => {
  return (
    <IconModal
      style={{ ...subMenuIconStyle, ...style }}
      Icon={<Act />}
      onClick={onClick}
    />
  );
};

export const IconPpe = ({ style, onClick }) => {
  return (
    <IconModal
      style={{ ...subMenuIconStyle, ...style }}
      Icon={<Ppe />}
      onClick={onClick}
    />
  );
};

export const IconTar = ({ style, onClick }) => {
  return (
    <IconModal
      style={{ ...subMenuIconStyle, ...style }}
      Icon={<Tar />}
      onClick={onClick}
    />
  );
};

export const IconQty = ({ style, onClick }) => {
  return (
    <IconModal
      style={{ ...subMenuIconStyle, ...style }}
      Icon={<Qty />}
      onClick={onClick}
    />
  );
};
