import { ReactComponent as Circle } from '../../assets/icon/circle.svg';
import { ReactComponent as Pen } from '../../assets/icon/newpen.svg';
import { ReactComponent as Garbage } from '../../assets/icon/garbage.svg';
import { ReactComponent as FunctionString } from '../../assets/icon/functionString.svg';
import { ReactComponent as JobTitle } from '../../assets/icon/JobTitle.svg';
import { ReactComponent as TechData } from '../../assets/icon/TechData.svg';

const IconBox = (props) => {
  return (
    <span
      role="img"
      aria-label="icon-press"
      className={`anticon ${props.className}`}
      onClick={props.onClick}
      style={props.style}
    >
      {<props.Icon style={{ color: props.style?.color }} />}
    </span>
  )
}

export const IconCircle = (props) => <IconBox {...props} Icon={Circle} />
export const IconGarbage = (props) => <IconBox {...props} Icon={Garbage} />
export const IconPen = (props) => <IconBox {...props} Icon={Pen} />
export const IconFunctionString = (props) => <IconBox {...props} Icon={FunctionString} />
export const IconJobTitle = (props) => <IconBox {...props} Icon={JobTitle} />
export const IconTechData = (props) => <IconBox {...props} Icon={TechData} />

