import React, { useState , useEffect} from 'react'
import styled from 'styled-components';
import {
  IconTheme
} from '../Icon'

const theme = localStorage.getItem('theme')

const bool = (themeString) => {
  if (themeString === 'false') {
    return false
  } else {
    return true
  }
}

const Wrapper = styled.div`
  margin-right: 5px;

  .icon {
    color: ${({ theme }) => {
      //console.log('theme.modalColor = ', theme.modalColor)
      return theme.modalColor
    }};
  }
  
`

function ThemeButton(props) { 

  // eslint-disable-next-line no-use-before-define
  const [s_state,setState] = useState(bool(theme))

  const is_change_theme = () => { 
    setState((prevState) => !prevState)
  }


  useEffect(() => {
    props.onClick(s_state)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[s_state])

  const themeButtonStyle = {
    // width: '45px',
    // height: '64px',
    // cursor: 'pointer',
    // transition: '1s',
    // position: 'relative',
    // verticalAlign: 'middle',
  }

  const themeIconStyle = {
    height: '36px',
    width: '36px',
    fontWeight: '400',
    position: 'absolute',
    top: '45%',
    left: '100%',
    transform: 'translate(-100%, -50%)',
    cursor: 'pointer',
  }
  return (
    <Wrapper
      onClick={is_change_theme}
      // style={themeButtonStyle}
      alt='換色'
    >
      {s_state ?
        <IconTheme className='icon' style={ themeIconStyle } />:
        <IconTheme className='icon' style={ themeIconStyle }/>
      }
    </Wrapper>
  )

  // return (
  //   <div
  //     onClick={is_change_theme}
  //     style={themeButtonStyle}
  //     alt='換色'
  //   >
  //     {s_state ?
  //       // <ThemeSvg style={themeIconStyle} />:
  //       // <ThemeSvg style={ themeIconStyle }/>
  //       <SocialMedia.LightIcon style={themeIconStyle} />:
  //       <SocialMedia.DarkIcon style={ themeIconStyle }/>
  //     }
  //   </div>
  // )
}
export default ThemeButton