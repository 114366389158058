import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";

import CustomModal from '../../../../components/antdCustom/CustomModal';

import { Form, Button, Row, Col, Input, message, Table, Select } from 'antd';
import { fontSize } from '../../../../styled/publicTag';
import { modifyWpcTb } from '../../../../services/api';
import { MenuOutlined } from "@ant-design/icons";
import { DataContext } from '../../index';
import { PlusOutlined } from '@ant-design/icons'
import './index.css';
import {
  IconTrash,
  IconPen
} from '../../../../components/Icon';

import TbModalContainer from './TbModalContainer';

const innerWidth = window.innerWidth;

const StyleTable = styled(Table)`
  
  .border {
    border: 1px solid #c5c5c5;
    border-radius: 5px;
  }
  
  /* 滾動條樣式 */
  ::-webkit-scrollbar-track-piece {
    background-color: #f8f8f8;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height:5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    background-clip: padding-box;
    min-height: 7px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
    border-radius: 10px;
  }

  // 勾勾的顏色
  .successIcon {
    color: ${({ theme }) => theme.Color1};
  }

  //表格內容
  .ant-table-tbody .ant-table-cell {
    padding: 8px;
  }

  // table 顯示頁碼的地方
  .ant-pagination-total-text h4 {
    font-size: ${fontSize.text};
  }

  .ant-table-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  //table 的圓角
  .ant-table-container,
  .ant-table {
    border-radius: 10px !important;
  }

  .ant-table-body {
    border-bottom: .5px solid rgb(217, 217, 217);
    border-left: .5px solid rgb(217, 217, 217);
    border-right: .5px solid rgb(217, 217, 217);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  /* width */
  .ant-table-body::-webkit-scrollbar {
    width: 10px !important;
  }

  // 讓滾動條下半部不要擋住table的圓角
  .ant-table-body::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    border-radius: 10px;
  }

  .checkIcon {
    text-align: center;
    font-size: 20px;
  }

  /* .checkIcon > * {
    color: ${({ theme }) => theme.Color3};
  } */

  // 表頭字體大小
  .ant-table-thead .ant-table-cell {
    padding: 8px;
    font-size: ${innerWidth <= 1440 ? '12px' : '16px'};
    white-space: nowrap;
  }

  .center {
    text-align: center;
  }

  .Icon {
    cursor: pointer;
  }

  .hidden {
    display: none
  }

  .fileLink {
    color: ${({ theme }) => theme.Color3};
    cursor: pointer;
  }

  .row-min-width {
    min-width: 100px;
  }
  .clickRowStyle {
    background-color: rgba(253, 255, 203, .5);
  }

  .row-dragging {
    background: red;
    border: 1px solid #ccc;
  }
  

  .row-dragging td {
    padding: 16px;
  }

  .row-dragging .drag-visible {
    visibility: visible;
  }
  .ant-form-item {
    margin-bottom: 0px!important;
  }
`;

const Wrapper = styled.div`
  .row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
    color: green
  }

  .row-dragging td {
    padding: 16px;
    color: pink
  }

  .row-dragging .drag-visible {
    visibility: visible;
    color: red
  }
`

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#CCC" }} />
));

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);



const ModalContainer = ({className, s_tableData, set_s_tableData, set_s_showModal}) => {

  const [ dataSource, set_dataSource ] = useState([]);
  const { c_lightData } = useContext(DataContext);
  const [ s_Siptype, set_s_Siptype ] = useState([]);

  const [ s_isShow, set_s_isShow ] = useState(false); // 要不要開三階畫面的開關
  const [ s_editData, set_s_editData ] = useState({});

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const Create = () => {
    const tmp = [...dataSource];
    tmp.push({
      PN: dataSource[0].PN,  // 料號
      wpcset: dataSource[0].wpcset,  // 途程組
      wpcSN: 0,  // 途程號
      nextSN: 0,  // 次途程號
      wsID: dataSource[0].wsID,  // 站別
      bfnetwt: 0,  // 產前標準單重
      afnetwt: 0,  // 產後標準單重
      note: '',  // 途程備註
      // key: (dataSource.length+1).toString()
    });
    tmp[tmp.length - 1].key = (tmp.length).toString()
    // alert((dataSource.length+1).toString())
    const tmpArray = tmp.map((item, index) => {
      return {
        ...item,
        'wpcSN': (index+1).toString(), 
        'nextSN': tmp.length === index+1 ? 'T' : (index+2).toString(), 
        'wsID': item.wsID ? item.wsID : 'WS01'
      }
    })
    console.log(tmpArray)
    set_dataSource(tmpArray);
  }

  const Edit = rowData => {
    set_s_isShow(true);
    set_s_editData(rowData)
  }

  const Delete = rowData => {
    const _newData = [...dataSource].filter(item => item.wpcSN !== rowData.wpcSN && item.nextSN !== rowData.nextSN);
    
    console.log('_newData = ', _newData)
    
    const newData = _newData.map((item, index) => {
      return {
        ...item,
        'wpcSN': (index+1).toString(), 
        'nextSN': _newData.length === index+1 ? 'T' : (index+2).toString(), 
        'wsID': item.wsID ? item.wsID : 'WS01'
      }
    })

    set_dataSource(newData)
  }

  const tableColumns = [
    {
      title: "排序",
      dataIndex: "sort",
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      align: 'center',
      className: "drag-visible",
      render: (text, rowData, index) => {
        const bol = editingKey !== ''
        return bol ? '' : <DragHandle />
      },
    },
    {
      title: '料號',
      dataIndex: 'PN',
      key: 'PN',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${200}px`,
    },
    {
      title: '途程組',
      dataIndex: 'wpcset',
      key: 'wpcset',
      align: 'center',
      width: innerWidth <= 1440 ? `${130}px` : `${100}px`,
      
    },
    {
      title: '途程號',
      dataIndex: 'wpcSN',
      key: 'wpcsetNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${100}px`,
      render: (text, rowData, index) => {
        return {
          props: {
            style: { backgroundColor: '#f7e7af' }
          },
          children: text
        };
      }
    },
    {
      title: '次途程號',
      dataIndex: 'nextSN',
      key: 'nextSN',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${100}px`,
      render: (text, rowData, index) => {
        return {
          props: {
            style: { backgroundColor: '#f7e7af' }
          },
          children: text
        };
      }
    },
    {
      title: '站別',
      dataIndex: 'wsID',
      key: 'wsID',
      align: 'center',
      width: innerWidth <= 1440 ? `${200}px` : `${100}px`,
      render: (text, rowData, index) => {
        return {
          props: {
            style: { backgroundColor: '#f7e7af' }
          },
          children: text
        };
      }
    },
    {
      title: '產前標準單重',
      dataIndex: 'bfnetwt',
      key: 'bfnetwt',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${120}px`,
    },
    {
      title: '產後標準單重',
      dataIndex: 'afnetwt',
      key: 'afnetwt',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${120}px`,
    },
    {
      title: '途程備註',
      dataIndex: 'note',
      key: 'note',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${350}px`,
    },
    {
      title: '更新時間',
      dataIndex: 'updtTstr',
      key: 'updtTstr',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${150}px`,
    },
    {
      title: '操作',
      dataIndex: 'render',
      key: 'render',
      width: innerWidth <= 1440 ? `${50}px` : `${50}px`,
      align: 'center',
      render: (text, rowData, index) => (
        <div style={{display: 'flex', justifyContent: 'space-around'}}>
          <div style={{display: 'flex'}}>
            <IconPen onClick={() => Edit(rowData)} className='icon' />
            <IconTrash onClick={() => Delete(rowData)} className='icon' /> 
          </div>
        </div>
      )
    }
  ];

  const isEditing = (record) => {
    // console.log(editingKey.includes(record.key))
    return editingKey === record.key;
  };

  // 拖曳結束時更新資料順序，並重新排序sopno
  const onSortEnd = ({ oldIndex, newIndex }) => {
    // 更改陣列順序
    function arrayMoveMutable(array, fromIndex, toIndex) {
      const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;
     
      if (startIndex >= 0 && startIndex < array.length) {
       const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;
     
       const [item] = array.splice(fromIndex, 1);
       array.splice(endIndex, 0, item);
      }
    }
    // 更改陣列順序
    function arrayMoveImmutable(array, fromIndex, toIndex) {
      array = [...array];
      arrayMoveMutable(array, fromIndex, toIndex);

      const tmpArray = array.map((item, index) => {
        return {
          ...item,
          'wpcSN': (index+1).toString(), 
          'nextSN': array.length === index+1 ? 'T' : (index+2).toString(), 
          'wsID': item.wsID ? item.wsID : 'WS01'
        }
      })

      return tmpArray;
    }

    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      // console.log("Sorted items: ", newData);
      const newData1 = newData.map((item, index) => {

        return {
          ...item,
          key: index.toString(),
          index: index,
          sopno: index+1,
        }
      })
      console.log('newData1 = ', newData1)
      set_dataSource(newData1);
    }
  };

  // 可被 drag 的區域
  const DraggableContainer = (props) => {
    // console.log('props = ', props)
    return (
      <SortableContainer
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={onSortEnd}
        {...props}
      />
    );
  };

  // 被拖曳的row
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x.index === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const mergedColumns = tableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => {
        return ({
          record,
          // inputType: col.dataIndex === 'age' ? 'number' : 'text',
          // dataIndex: col.dataIndex,
          title: col.title,
          dataKey: col.dataIndex,
          bgColor: col.bgColor,
          editing: isEditing(record).toString(),
        })
      },
    };
  });

  // 確定送出
  const submit = async () => {
    
    const newData = dataSource.map((item, index) => {
      return {
        ...item,
        setcheck2: parseFloat(item.setcheck2),
        setcheck2UCL: parseFloat(item.setcheck2UCL),
        setcheck2LCL: parseFloat(item.setcheck2LCL),

        setcheck3: parseFloat(item.setcheck3),
        setcheck3UCL: parseFloat(item.setcheck3UCL),
        setcheck3LCL: parseFloat(item.setcheck3LCL),
        
        LCL: parseFloat(item.LCL),
        UCL: parseFloat(item.UCL),

        stepC: parseInt(item.stepC),
        flagC: parseInt(item.flagC),
        feedC: parseInt(item.feedC),
        value: parseInt(item.value),
        setcheck2tol: parseFloat(item.setcheck2tol),
      }
    })
    console.log('要給後端的資料 = ', newData)
    const res = await modifyWpcTb(newData);
    console.log('res = ', res)
    res.status ? message.success(res.msg) : message.error(res.msg);
    // alert('wait')
    set_s_showModal(false)
  }

  // 只能這樣寫，不然會出錯
  useEffect(() => {

    const newData = s_tableData.length > 0 ? s_tableData.map((item, index) => {
      return {
        key: index.toString(),
        index: index,
        ...item
      }
    }) : []

    set_dataSource(newData);

    console.log('s_tableData = ', s_tableData)
  }, []);

  return (
    <Wrapper className={className}>
      <Form form={form} component={false}>
        <Row>
          <Col span={24} style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '10px'}}>
            <Button 
              onClick={Create} 
              type="primary" 
              style={{borderRadius: '5px', marginRight: '0px'}} 
              icon={<PlusOutlined />} 
              size='large'
            >
              新增
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <StyleTable
              pagination={false}
              dataSource={dataSource}
              columns={mergedColumns}
              rowKey="index"
              rowClassName="editable-row"
              scroll={{ x: 'max-content', y: 400 }}
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                  // cell: EditableCell,
                },
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item>
              <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '10px'}}>
                <Button disabled={editingKey !== ''} style={{borderRadius: '5px'}} size='large' type="primary" onClick={submit}>
                  確定修改
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {s_isShow ? (
        <CustomModal 
          width='60%' 
          title={ Object.keys(s_editData).length === 0 ? "新增產品途程明細" : "修改產品途程明細" } 
          visible={s_isShow} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_s_isShow(false)}
        >
          <TbModalContainer 
            set_s_isShow={set_s_isShow} 
            s_editData={s_editData} 
            set_s_editData={set_s_editData} 
            dataSource={dataSource} 
            set_dataSource={set_dataSource} 
          />
        </CustomModal>
      ) : null}
      
    </Wrapper>
  )
};

export default ModalContainer;