import { useState, useEffect } from "react";
import { Chart, Tooltip, Legend, Point, Line, Axis, LineAdvance } from "bizcharts";
import moment from "moment";

const ProductionLineChart = ({ chartData, infoData }) => {

  const [hourConfig, setHourConfig] = useState({
    range: [0, 0, 0, 0, 0]
  })
  const [inConfig, setInConfig] = useState({
    range: [0, 0, 0, 0, 0]
  })
  //左=平均產速(inC/hr)，右=總產出數(inC)
  // let chartData = [];

  // for (let i = 0; i < 24; i++) {
  //   chartData.push({
  //     time: `${i}`,
  //     hourC: i * Math.floor(Math.random() * 10), //每時產出
  //     hourC: Math.floor(Math.random() * 100), //平均產速(inC/hr)
  //     inC: i * 24, //總產出數(inC)
  //   });
  // }
  const getDate = () => {

    const getTimeArray = (start, end) => {
      const quarterHours = ["00"];
      let times = [];
      for (let i = start; i <= end; i++) {
        if (i === end) {
          times.push(i + ":" + quarterHours[0]);
        } else {
          for (let j = 0; j < quarterHours.length; j++) {
            if (i < 10) {
              times.push("0" + i + ":" + quarterHours[j]);
            } else {
              times.push(i + ":" + quarterHours[j]);
            }
          }
        }
      }
      return times;
    }

    let morning = getTimeArray(8, 20);
    morning.pop();

    let night = getTimeArray(20, 24);
    night.pop();

    let newMorning = getTimeArray(0, 8);
    newMorning.pop();

    console.log(morning.concat(night).concat(newMorning).length)

    return morning.concat(night).concat(newMorning);
  };

  const timeArray = getDate();

  const new_chartData = chartData.length > 0 && chartData.map((item, index) => {
    const tmp = {
      ...item,
      newTime: timeArray[index]
    }
    return tmp
  })


  const getNextInt = (item, power) => {
    return (Math.floor(item / power) + 1) * power
  }

  const getSection = (nextMax) => {
    let array = [0]
    for (let i = 1; i < 5; i++) {
      array.push(Math.floor(nextMax / 4 * i))
    }
    return array
  }


  useEffect(() => {
    if (Object.getOwnPropertyNames(infoData).length !== 0) {
      let arrayOfHourC = chartData.length > 0 ? chartData.map(item => item.hourC) : []

      // 取得最大值(inC最後一筆必為最大)
      const maxInC = infoData.inC
      const maxHourC = Math.max(...arrayOfHourC)

      let inC = infoData.inC + ""
      let hourC = maxHourC + ""

      // 取得指數
      const powerOfInC = 10 ** (inC.length - 1)
      const powerOfHourC = 10 ** (hourC.length - 1)
      // 無條件進位到最大位數
      const nextMaxInC = getNextInt(maxInC, powerOfInC)
      const nextMaxHourC = getNextInt(maxHourC, powerOfHourC)
      // 取得Y軸所有區間
      let inSection = getSection(nextMaxInC)
      let hourSection = getSection(nextMaxHourC)

      setInConfig({ ...inConfig, range: inSection })
      setHourConfig({ ...hourConfig, range: hourSection })
    }

  }, [infoData])

  const scale = {
    //設定Y軸座標
    hourC: {
      type: "linear-strict",
      tickCount: 5,
      ticks: hourConfig.range,
    },
    inC: {
      type: 'linear-strict',
      tickCount: 5,
      ticks: inConfig.range,
    }
  };

  let chartIns = null;

  const colors = ["#6394f9", "#62daaa"];

  return (
    <Chart
      autoFit
      height={430}
      data={new_chartData}
      scale={scale}
      onGetG2Instance={(chart) => {
        chartIns = chart;
      }}
    >
      <Axis
        name="hourC"
        label={{
          formatter: (val) => `${val}`,
          style: { fontSize: 16 }
        }}
      />
      <Axis
        name="newTime"
        label={{
          style: { fontSize: 16 },
          rotate: -1.2,
          offset: 30
        }}
      />
      <Legend
        custom={true}
        allowAllCanceled={true}
        items={[
          {
            value: "hourC",
            name: "平均產速",
            marker: {
              symbol: "circle",
              style: { fill: colors[0], r: 5 },
            },
          },
          {
            value: "inC",
            name: "總生產數",
            marker: {
              symbol: "circle",
              style: { fill: colors[1], r: 5 },
            },
          },
        ]}
        onChange={(ev) => {
          console.log("ev", ev);
          const item = ev.item;
          const value = item.value;
          const checked = !item.unchecked;
          const geoms = chartIns.geometries;

          for (let i = 0; i < geoms.length; i++) {
            const geom = geoms[i];

            if (geom.getYScale().field === value) {
              if (checked) {
                geom.show();
              } else {
                geom.hide();
              }
            }
          }
        }}
      />
      <Tooltip
        shared
        showCrosshairs
        domStyles={{
          "g2-tooltip": {
            boxShadow: "none",
            color: "#fff",
            backgroundColor: "#222",
            height: "90px",
            width: "300px",
            fontSize: "25px",
          },
        }}
        crosshairs={{
          type: "y",
        }}
        style={{
          color: "red",
        }}
      />
      <LineAdvance
        position="newTime*hourC"
        color={colors[0]}
        shape="smooth"
        size={4}
        area
        tooltip={[
          "newTime*hourC",
          (time, hourC) => {
            const myTitle = time
            return {
              name: "每時產出",
              value: `${hourC}`,
              title: myTitle,
            };
          },
        ]}
      />
      <Point
        position="newTime*hourC"
        color={colors[0]}
        size={3}
        shape="circle"
        tooltip={false}
      />
      <Line
        position="newTime*inC"
        color={colors[1]}
        size={4}
        shape="smooth"
        tooltip={[
          "newTime*inC",
          (time, inC) => {
            const myTitle = time;
            return {
              name: "總生產數",
              value: `${inC}`,
              title: myTitle,
            };
          },
        ]}
      />
      <Point
        position="newTime*inC"
        color={colors[1]}
        size={3}
        shape="circle"
        tooltip={false}
      />
    </Chart>
  );
};

export default ProductionLineChart;
