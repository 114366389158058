import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

import CustomTable from '../../../components/antdCustom/CustomTable';
import { MySubtitleH4 } from '../../../styled/publicTag.js';

import { getResumeWpc } from '../../../services/api';

const innerWidth = window.innerWidth;

const Wrapper = styled.div`
  .Header {
    display: flex;
    align-items: center;
  }

  .Header .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }

  .Header .item:nth-child(1)  {
    flex: 1
  }

  .Header .item:nth-child(2)  {
    flex: 2
  }

  .Header .item .item-header {
    color: #85b4e7;
    /* font-weight: 600; */
    font-size: 20px;
  }

  .Header .item p {
    font-size: 17px;
    height: 100%;
    margin-bottom: 0px;
  }

  .Container {
    padding-bottom: 10px;
  }

  .Footer {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
  }
`

const ModalContainer = ({s_rowData, set_s_rowData, set_s_is_show}) => {

  const [ s_tableData, set_s_tableData ] = useState([]);

  const [pageInfo, setPageInfo] = useState({
    currentPage: 0,
    pageItemNumber: 10,
    totalItems: 0,
    pageSize: 10,
    page: 1
  });

  const tableColumns = [
    {
      title: '工單號',
      dataIndex: 'woN',
      key: 'woN',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${160}px`,
      render: (text, rowData, index) => {
        return s_rowData.woN
      }
    },
    {
      title: '途程號',
      dataIndex: 'wpcSN',
      key: 'wpcSN',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
    },
    {
      title: '次途程號',
      dataIndex: 'nextSN',
      key: 'nextSN',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
    },
    {
      title: '站別',
      dataIndex: 'wsID',
      key: 'wsID',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
    },
    {
      title: '站名',
      dataIndex: 'wsNM',
      key: 'wsNM',
      align: 'center',
      width: innerWidth <= 1440 ? `${100}px` : `${100}px`,
    },
    {
      title: '工單歷程',
      dataIndex: 'lifeF',
      key: 'lifeF',
      align: 'center',
      width: innerWidth <= 1440 ? `${80}px` : `${130}px`,
      render: (text, rowData, index) => {
        const key = rowData.lifeF;
        const keyMap = {
          "0": "發佈",
          "1": "登錄",
          "B": "上模中",
          "R": "上模中",
          "2": "開始生產",
          "3": "報停工",
          "4": "報復工",
          "5": "報下線",
          "6": "再上線",
          "7": "報完工",
          "9": "撤登",
          "L": "物控合格",
          "P": "質控合格",
          "W": "特採過站",
          "Q": "隔離處置",
          "G": "過站"
        };
        return key ? keyMap[key] : '';
      }
    },
    {
      title: '歷程時間',
      dataIndex: 'newlifeFT',
      key: 'newlifeFT',
      align: 'center',
      width: innerWidth <= 1440 ? `${120}px` : `${100 * 1.6}px`
    },
    {
      title: '投產數量',
      dataIndex: 'inC',
      key: 'inC',
      align: 'center',
      width: innerWidth <= 1440 ? `${80}px` : `${100 * 1.3}px`,
      render: (text, rowData, index) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    },
    {
      title: '計劃產量',
      dataIndex: 'planqty',
      key: 'planqty',
      align: 'center',
      width: innerWidth <= 1440 ? `${80}px` : `${100 * 1.3}px`,
      render: (text, rowData, index) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </div>
        )
      }
    },
    {
      title: '良品數量',
      dataIndex: 'okC',
      key: 'okC',
      align: 'center',
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    },
    {
      title: '不良數量',
      dataIndex: 'ngC',
      key: 'ngC',
      align: 'center',
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
      render: (text, rowData, index) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    }
  ];

  const handleClose = () => {
    set_s_is_show(false)
  }

  useEffect(() => {

    const call_getResumeWpc = async () => {
      const res = await getResumeWpc({
        'woN': s_rowData.woN,
        'PN': s_rowData.PN
      });

      console.log(res)

      res.status ? set_s_tableData(res.data) : set_s_tableData([]);
    };

    call_getResumeWpc();

  }, []);

  useEffect(() => {
    return () => {
      set_s_rowData({})
    }
  }, []);

  return (
    <Wrapper>
      <div className="Header">
        <div className="item">
          <span className='item-header'>料號：</span>
          <p>{s_rowData.PN}</p>
        </div>
        <div className="item">
          <span className='item-header'>品名：</span>
          <p>{s_rowData.pdtNM}</p>
        </div>
      </div>
      <div className="Container">
      <CustomTable
        scroll={{ x: "max-content", y: 400 }}
        columns={tableColumns}
        dataSource={s_tableData && s_tableData.length > 0 && s_tableData.map((item, index) => {
          return { ...item, key: index }
        })}
        pagination={{
          defaultCurrent: 1,
          total: pageInfo.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: pageInfo.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            console.log(current)
            setPageInfo({
              ...pageInfo,
              page: current,
              pageSize: size
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />
      </div>
      <div className="Footer">
        <Button
          type='primary'
          size='large'
          style={{borderRadius: '5px'}} 
          onClick={handleClose}
        >
          關閉
        </Button>
      </div>
    </Wrapper>
  )
};

export default ModalContainer;