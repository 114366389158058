import { instance, conn, url } from "../handlerAPI";

//-----------------------------------------取得所有入庫資料----------------------------------------
export const getAllPlanInHd = (dataObj) => {
  return instance.get(`${conn}://${url}/api/wms/getAllPlanInHd`, {params: dataObj});
};

//-----------------------------------------取得所有入庫明細----------------------------------------
export const getAllPlanInDetail = (dataObj) => {
  return instance.get(`${conn}://${url}/api/getAllPlanInDetail`, {params: dataObj});
};

//----------------------------------------- 新增入庫資料----------------------------------------
export const addPlanIn = (dataObj) => {
  return instance.post(`${conn}://${url}/api/wms/addProductPlanIn`, dataObj);
};

//-----------------------------------------取得全部庫房資料----------------------------------------
export const getAllWh = () => {
  return instance.get(`${conn}://${url}/api/getAllWh`);
};

//-----------------------------------------取得全部 H 單----------------------------------------
export const getAllHub = () => {
  return instance.get(`${conn}://${url}/api/wms/getAllHub`);
};

//-----------------------------------------取得表身資料----------------------------------------
export const getAllPlanInTb = (rowcode) => {
  console.log('rowcode = ', rowcode)
  return instance.get(`${conn}://${url}/api/wms/getAllPlanInTb?opcode=${rowcode.opcode}&jobN=${rowcode.jobN}`);
};

//-----------------------------------------刪除表頭資料----------------------------------------
export const deleteProductPlanIn = (rowcode) => {
  return instance.post(`${conn}://${url}/api/wms/deleteProductPlanIn`, rowcode);
};

//-----------------------------------------循環表頭資料----------------------------------------
export const txnProductPlanIn = (rowcode) => {
  return instance.post(`${conn}://${url}/api/wms/txnProductPlanIn`, rowcode);
};

//----------------------------------------- 錄入入庫資料----------------------------------------
export const modifyStaticPlanIn = (dataObj) => {
  return instance.post(`${conn}://${url}/api/wms/modifyStaticPlanIn`, dataObj);
};