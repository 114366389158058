import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  MySubtitleH4,
  MyThemeH1,
  MySubtitleH3,
} from "../../styled/publicTag";
import {
  Row,
  Col,
  Input,
  Button,
  message,
  DatePicker,
  Form,
  InputNumber,
  Select
} from "antd";
import moment from "moment";
import CustomTable from "../../components/antdCustom/CustomTable";
import CustomModal from "../../components/antdCustom/CustomModal";
import { getAps,modifyAps,toWos,apsClose,getAllLine, getAllStation } from "../../services/api";
import { IconPen } from "../../components/Icon";
import Title from '../../components/Custom/Title';

const { RangePicker } = DatePicker;

const ApsManagementElement = ({ className }) => {
  const [form] = Form.useForm();

  const tableCol = [
    {
      title: "工單號",
      dataIndex: "woN",
      key: "woN",
      width: "150px",
      align: "center",
    },
    { title: "料號", dataIndex: "PN", key: "PN", align: "center" },
    {
      title: "品名",
      dataIndex: "pdtNM",
      key: "pdtNM",
      width: "350px",
      align: "center",
      render: (text, rowData, index) => {
        return {
          props: {
            style: { textAlign: 'left' }
          },
          children: (
            <div>
              {text}
            </div>
          )
        };
      }
    },
    {
      title: "預計投產數量",
      dataIndex: "planqty",
      key: "planqty",
      width: "200px",
      align: "center",
      render: (text, rowData, index) => (
        <div style={{display: 'flex',justifyContent: 'center'}}>
          {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </div>
      )
    },
    {
      title: "線別",
      dataIndex: "wlID",
      key: "wlID",
      width: "200px",
      align: "center",
    },
    {
      title: "廠別",
      dataIndex: "orgID",
      key: "orgID",
      width: "200px",
      align: "center",
    },
    {
      title: "班別",
      dataIndex: "class",
      key: "class",
      width: "200px",
      align: "center",
    },
    {
      title: "開立時間",
      dataIndex: "creatT",
      key: "creatT",
      width: "200px",
      align: "center",
      render: (text) => {
        return moment(text).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: "操作",
      dataIndex: "action",
      key: "action",
      width: "50px",
      align: "center",
      render: (_, rowData) => {
        return (
          <div
            style={{display: 'flex', justifyContent: 'center'}}
            onClick={() => {
              setModifyModal({
                ...modifyModal,
                isOpen: true,
                thisAps: rowData,
              });
            }}
          >
            <IconPen className="icon" />
          </div>
        );
      },
    },
  ];

  const [s_AllStation_options, set_s_AllStation_options] = useState([]); // 所有工站選單

  const [modifyModal, setModifyModal] = useState({
    isOpen: false,
    thisAps: {},
  });

  const [toWosData,setWosData] = useState([])

  const [queryString, setQueryString] = useState({
    wsID: "",
    woN: "",
    PN: "",
    pdtNM: "",
    startT: "",
    endT: "",
    page: 1,
    quertString: "",
    totalItems: 0,
    //totalItems: 25,
    currentPage: 1,
    pageItemNumber: 10,
    startItemNumber: 1,
    endItemNumber: 10,
  });

  const [wlItem ,setWlItem] = useState([])

  const [tableData, setTableData] = useState([]);

  const {Option} = Select

  const call_getAllLine = async() =>{
    try {
      const res = await getAllLine();
      if (res.status){
        const options = res.data.map(item=><Option key={item.wlID} value={item.wlID}>{item.wlNM}</Option>)
        setWlItem(options)
      }else{
        message.error(res.msg)
      }
    }catch(e){
      console.log("call_getAps",e)
    }
  }
  const call_getAllStation = async () => {
    try {
      const res = await getAllStation();
      if(res.status === true && res.data.length > 0) {
        //console.log('全部工站資料 = ', res.data);
        let options = res.data.map(item => <Select.Option key={item.wsID} value={item.wsID}>{item.wsNM}</Select.Option>);
        set_s_AllStation_options(options);
        // set_s_AllStation(res.data);
        
      }
    } catch (e) {
      console.log('---------- api getAllStaffPerm error ----------');
      console.log(e)
    }
  }

  const call_getAps = async () => {
    try {
      const res = await getAps(queryString);
      if (res.status) {
        const apsData = res.data.woNs.map((x) => {
          return { ...x, key: x.SN };
        });
        setTableData(apsData);
        setQueryString({ ...queryString, ...res.data.pageInfo });
        message.success(res.msg);
      } else {
        setTableData([]);
        message.error(res.msg);
      }
    } catch (e) {
      console.log("call_getAps", e);
    }
  };

  const call_modifyAps = async (data) =>{
    try {
      const res = await modifyAps(data)
      if(res.status){
        message.success(res.msg)
        setModifyModal({...modifyModal,isOpen: false})
        call_getAps()
      }else{ 
        message.error(res.msg)
      }
    }catch (e){
      console.log(e)
    }
  }

  useEffect(() => {
    //應該是多餘的吧
    // call_getAps();
    call_getAllStation();
    call_getAllLine();
  }, []);

  useEffect(() => {
    call_getAps();
  },[queryString.page, queryString.pageSize])

  const call_apsClose = async() => {
    try {
      const res = await apsClose(toWosData)
      if (res.status){
        message.success(res.msg)
        call_getAps()
      }else{
        message.error(res.msg)
      }
    }catch (e) {
      console.log(e)
    }
  }

  const call_toWos = async() => {
    try {
      const res = await toWos(toWosData)
      if (res.status){
        message.success(res.msg)
        call_getAps()
      }else{
        message.error(res.msg)
      }
    }catch (e) {
      console.log(e)
    }
  }

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setWosData(selectedRows)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const modifyRowData = async () => {
    let result = await form.validateFields();
    result.creatT = moment(result.creatT).format("YYYY-MM-DD")
    result["SN"] = modifyModal.thisAps.SN
    call_modifyAps(result)
  };

  useEffect(() => {
    form.resetFields();
  }, [modifyModal]);

  const formInitValue = {
    ...modifyModal.thisAps,
    creatT: moment(modifyModal.thisAps.creatT),
  };

  return (
    <>
      <Title title='生管派工管理'>
        {/* <Select 
          style={{marginRight: '15px', width: '150px'}} 
          size='large'
          value={queryString.wsID}
          onChange={value => {
            setQueryString({ ...queryString, wsID: value });
          }}
        >
          {s_AllStation_options}
        </Select> */}
        <Input
          style={{width: '180px', marginRight: '15px'}}
          placeholder="查詢工單號"
          size="large"
          value={queryString.woN}
          onChange={(e) => {
            setQueryString({ ...queryString, woN: e.target.value });
          }}
        />
        <Input
          style={{width: '180px', marginRight: '15px'}}
          placeholder="查詢料號"
          size="large"
          value={queryString.PN}
          onChange={(e) => {
            setQueryString({ ...queryString, PN: e.target.value });
          }}
        />
        <Input
          placeholder="查詢品名"
          style={{width: '180px', marginRight: '15px'}}
          size="large"
          value={queryString.pdtNM}
          onChange={(e) => {
            setQueryString({ ...queryString, pdtNM: e.target.value });
          }}
        />
        <RangePicker
          size="large"
          style={{marginRight: '15px'}}
          showTime={{
            hideDisabledOptions: true,
            defaultValue: [
              moment("00:00:00", "HH:mm:ss"),
              moment("23:59:59", "HH:mm:ss"),
            ],
          }}
          format="YYYY-MM-DD HH:mm:ss"
          onChange={(_, dateStr) => {
            setQueryString({
              ...queryString,
              startT: dateStr[0],
              endT: dateStr[1],
            });
          }}
        />
        <Button 
          style={{borderRadius: '5px', marginRight: '15px'}}  
          size="large" 
          type="primary" 
          onClick={call_getAps}
        >
          搜尋
        </Button>
        <Button 
          style={{borderRadius: '5px', marginRight: '15px'}} 
          size="large" 
          type="primary" 
          onClick={call_toWos}
        >
          導入WOS
        </Button>
        <Button 
          style={{borderRadius: '5px'}} 
          size="large" 
          type="danger" 
          onClick={call_apsClose}
        >
          批量關帳
        </Button>
      </Title>
      
      <CustomTable
        columns={tableCol}
        dataSource={tableData}
        rowSelection={rowSelection}
        pagination={{
          defaultCurrent: 1,
          total: queryString.totalItems,
          // current: abcBoardConfig.page,
          showSizeChanger: true,
          pageSize: queryString.pageSize,
          pageSizeOptions: ["10", "20", "30"],
          onChange: (current, size) => {
            setQueryString({
              ...queryString,
              page: current,
              pageSize: size,
            })
          },
          showTotal: (total, range) => (
            <MySubtitleH4>
              共 {total} 項 ， 正在顯示第{range[0]}到{range[1]}項
            </MySubtitleH4>
          ),
        }}
      />

      <CustomModal
        title={<MyThemeH1>編輯生管派工</MyThemeH1>}
        visible={modifyModal.isOpen}
        onCancel={() => setModifyModal({ ...modifyModal, isOpen: false })}
        onOk={modifyRowData}
        cancelText="取消"
        destroyOnClose={true}
        width={"800px"}
        centered
        forceRender
      >
        <Form
          form={form}
          name="apsForm"
          layout="vertical"
          size="large"
          initialValues={formInitValue}
        >
          <Row justify="space-between" align="middle" gutter={[24, 24]}>
            <Col span={8}>
              <Form.Item
                label={<MySubtitleH3>工單號</MySubtitleH3>}
                name="woN"
                rules={[{ required: true, message: "請輸入工單號" }]}
                // initialValue={modifyModal.thisAps.woN}
              >
                <Input placeholder="請輸入工單號" disabled/>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<MySubtitleH3>料號</MySubtitleH3>}
                name="PN"
                rules={[{ required: true, message: "請輸入料號" }]}
                // initialValue={modifyModal.thisAps.PN}
              >
                <Input placeholder="請輸入料號" disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<MySubtitleH3>品名</MySubtitleH3>}
                name="pdtNM"
                rules={[{ required: true, message: "請輸入品名" }]}
                // initialValue={modifyModal.thisAps.pdtNM}
              >
                <Input placeholder="請輸入品名" disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<MySubtitleH3>預計投產數量</MySubtitleH3>}
                name="planqty"
                rules={[{ required: true, message: "請輸入預計投產數量" }]}
                // initialValue={modifyModal.thisAps.planqty}
              >
                <InputNumber
                  placeholder="請輸入預計投產數量"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<MySubtitleH3>線別</MySubtitleH3>}
                name="wlID"
                rules={[{ required: true, message: "請選擇線別" }]}
              >
                <Select placeholder="請輸入線別" >
                  {wlItem}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<MySubtitleH3>廠別</MySubtitleH3>}
                name="orgID"
                // initialValue={modifyModal.thisAps.orgID}
              >
                <InputNumber placeholder="請輸入廠別" style={{ width:"100%"}}/>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<MySubtitleH3>班別</MySubtitleH3>}
                name="class"
                // initialValue={modifyModal.thisAps.class}
              >
                <Input placeholder="請輸入班別" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={<MySubtitleH3>開立時間</MySubtitleH3>}
                name="creatT"
                // rules={[{ required: true, message: "請選擇開立時間" }]}
              >
                <DatePicker
                  disabled
                  placeholder="請選擇完成日期"
                  format={"YYYY-MM-DD"}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}></Col>
          </Row>
        </Form>
      </CustomModal>
    </>
  );
};

const ApsManagement = styled(ApsManagementElement)`
  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export default ApsManagement;
