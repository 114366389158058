import styled from 'styled-components';
import { fontSize } from '../../styled/publicTag.js';
import { Tabs } from 'antd';

const StyleTabs = styled(Tabs)`

  .ant-tabs-tab {
    font-size: ${fontSize.p};
    padding-bottom: 6px;
  }
`

const CustomTabs = ({onChange, defaultActiveKey, TabsData}) => {

  return (
    <StyleTabs defaultActiveKey={defaultActiveKey} onChange={onChange}>
      {TabsData && TabsData.map((item, index) => {
        return(
          <Tabs.TabPane key={item.key} tab={item.tabName} />
        )
      })}
      
      {/* <StyleTabs.TabPane tab="登錄途單" key="1">
        Content of 登錄途單
      </StyleTabs.TabPane>
      <StyleTabs.TabPane tab="物控途單" key="L">
        Content of 物控途單
      </StyleTabs.TabPane>
      <StyleTabs.TabPane tab="質控途單" key="PWQ">
        Content of 質控途單
      </StyleTabs.TabPane> */}
    </StyleTabs>
  )
};

export default CustomTabs;