import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { Form, Row, Col, Select, Input, Button, Divider, message } from 'antd';
import { modifyStaticPlanIn } from '../../../../../services/WMS/PlainIn';

const StyledForm = styled(Form)`

  .ant-input, .ant-picker, .ant-input-number {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
`;

const ModalContainer = ({s_editData, set_is_showModal, set_s_editData}) => {

  const [form] = Form.useForm();

  const textArea = {
    resize: 'none',
    fontSize: '35px',
    fontWeight: 'bold',
    textAlign: 'center',
    height: '120px',
    lineHeight: '110px'
  }

  const onSubmit = async values => {

      const res = await modifyStaticPlanIn({
        opcode: s_editData.opcode,
        PN: s_editData.PN,
        jobN: s_editData.jobN,
        sqty: values.sqty
      })
      res.status === true ? message.success(res.msg) : message.error(res.msg)

      set_is_showModal(false);
      set_s_editData({});
    
  }

  const onCancel = () => {
    set_is_showModal(false);
    set_s_editData({});
  }

  return (
    <Form.Provider>
      <StyledForm
        name="basic"
        form={form}
        initialValues={{
          // opcodeID: s_PlainInData.opcodeID,
        }}
        onFinish={onSubmit}
        autoComplete="off"
        layout="vertical"
        
        // layout="horizontal"
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Form.Item 
              // label={<label style={{color: '#6C6C6C'}}>靜態數量</label>}
              name="sqty"
            >
              <Input.TextArea
                style={textArea} 
                rows={2} 
                placeholder="輸入靜態數量"
                rules={[{ required: true, message: '請輸入靜態數量!' }]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider style={{margin: '6px'}} />
        <Row gutter={[24, 24]} style={{marginBottom: '20px'}}>
          <Col offset={16} span={8}>
            <div style={{display: 'flex',justifyContent: 'flex-end'}}>
              <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={onCancel}>
                取消
              </Button>
              <Form.Item>
                <Button size='large' type="primary" htmlType="submit" style={{borderRadius: '5px'}}>
                  確定
                </Button>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </StyledForm>
    </Form.Provider>
  )
};

export default ModalContainer;