import styled from "styled-components";
import { fontSize } from "./publicTag";
import { useState, useEffect, useCallback } from "react";
import checkUndefined from "../utility/checkUndefined";

const MyCardElement = ({
  title,
  content,
  align = "",
  color = "#58beb3",
  className,
  icon,
}) => {
  const [directionStyle, setDirectionStyle] = useState("");

  useEffect(() => {
    switch (align) {
      case "top":
        setDirectionStyle("my-card-direction-top");
        break;
      case "bottom":
        setDirectionStyle("my-card-direction-bottom");
        break;
      case "right":
        setDirectionStyle("my-card-direction-right");
        break;
      case "left":
        setDirectionStyle("my-card-direction-left");
        break;
      default:
        setDirectionStyle("");
        return;
    }
  }, []);

  const checkContent = useCallback(
    (c) => {
      if (c === String || c === Number || c === undefined) {
        return checkUndefined(c);
      } else {
        return c;
      }
    },
    [content]
  );

  return (
    <div className={className}>
      <div className={directionStyle}></div>
      <div className="my-card-header">
        <span className="my-card-icon">{icon}</span>
        {title}
      </div>
      <div className="my-card-content">{checkContent(content)}</div>
    </div>
  );
};

const MyCard = styled(MyCardElement)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  height: 100px;
  border-radius: 10px;
  border: ${({ theme }) => `0.1px solid ${theme.borderColor}`};
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background-color: ${({ theme }) => theme.pageBg};
  white-space: nowrap;
  .my-card-icon {
    height: 45px;
    width: 45px;
  }
  .my-card-icon > svg {
    height: 45px;
    width: 45px;
    font-size: 30px;
  }
  .my-card-header {
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: ${({ theme }) => `2px solid ${theme.borderColor}`};
    color: ${({ theme }) => theme.text};
    padding: 5px 20px;
    font-size: ${fontSize.h1};
    font-weight: 400;
  }
  .my-card-content {
    height: 50%;
    width: 100%;
    padding: 5px 20px;
    text-align: center;
    color: ${({ theme }) => theme.mainColor};
    font-weight: 400;
    font-size: ${fontSize.h2};
    white-space: nowrap; /*//强制文本在一行内输出 */
    overflow: hidden; /*隐藏溢出部分*/
    text-overflow: ellipsis; /*//對溢出部分加上...*/
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .my-card-direction-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 15px;
    background-color: ${({ color, theme }) =>
      color ? color : theme.mainColor};
    border-bottom: ${({ theme }) => `.5px solid ${theme.borderColor}`};
    border-radius: 10px 10px 0 0;
  }
  .my-card-direction-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 15px;
    background-color: ${({ color, theme }) =>
      color ? color : theme.mainColor};
    border-top: ${({ theme }) => `.5px solid ${theme.borderColor}`};
    border-radius: 0 0 10px 10px;
  }
  .my-card-direction-left {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
    width: 15px;
    background-color: ${({ color, theme }) =>
      color ? color : theme.mainColor};
    border-right: ${({ theme }) => `.5px solid ${theme.borderColor}`};
    border-radius: 10px 0 0 10px;
  }
  .my-card-direction-right {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 15px;
    background-color: ${({ color, theme }) =>
      color ? color : theme.mainColor};
    border-left: ${({ theme }) => `.5px solid ${theme.borderColor}`};
    border-radius: 0 10px 10px 0;
  }
`;

export default MyCard;
