import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Row, Col, Select, Input, Button, Divider, message } from 'antd';
import HubTab from './HubTab';
import { getAllWh, getAllHub, addPlanIn } from '../../../../services/WMS/PlainIn';

const {Option} = Select;

const StyledForm = styled(Form)`

  .ant-input, .ant-picker {
    border-radius: 5px;
  }

  .ant-select-selector {
    border-radius: 5px!important;
  }
`;

const ModalContainer = ({ opcodeID, s_PlainInData, set_s_PlainInData, set_is_openModal}) => {

  const [form] = Form.useForm();

  const [ s_AllWh, set_s_AllWh ] = useState([]); // 全部庫房資料
  const [ s_AllHub, set_s_AllHub ] = useState([]);  // 全部 H 單

  const [ s_selectHub, set_s_selectHub ] = useState({}); // 選中的 H 單資料



  const handleCancel = () => {
    set_is_openModal(false);
    set_s_PlainInData({});
  }

  // 第一次渲染取得所有庫房資料、H單資料
  useEffect(() => {
    
    // 庫房資料(倉別、倉名稱)
    const call_getAllWh = async () => {
      const res = await getAllWh();
      console.log('全部庫房(倉別)資料 res ===> ', res);
      res.status === true ? set_s_AllWh(res.data) : set_s_AllWh([]);
    }

    // H 單
    const call_getAllHub = async () => {
      const res = await getAllHub();
      console.log('全部H單資料 res ===> ', res);
      res.status === true ? set_s_AllHub(res.data) : set_s_AllHub([]);
    }

    call_getAllWh();
    call_getAllHub();
  }, []);

  // 第一次渲染如果有 s_PlainInData 就把H單資料存到state內
  useEffect(() => {
    if(Object.keys(s_PlainInData).length > 0) {

      const data = s_AllHub.filter(item => item.UUID === s_PlainInData.Hub);
      console.log('data = ', data)
      set_s_selectHub(data)
    }
  }, []);

  const onSubmit = async values => {
    
    console.log('s_selectHub = ', s_selectHub)
    const tmpValues = {
      ...values, 
      opcode: opcodeID,
      setidm: s_selectHub[0].setidm,
      UUID: s_selectHub[0].UUID,
      PN: s_selectHub[0].PN,
      inqty: s_selectHub[0].inqty,
      
    };

    console.log('tmpValues = ', tmpValues)

    const res = await addPlanIn(tmpValues);

    console.log('res = ', res)

    res.status === true ? message.success(res.msg) : message.error(res.msg);

    console.log('tmpValues = ', tmpValues);

    set_is_openModal(false)

  }

  // 選完入庫單後顯示的入庫單型態
  const typeVal = Object.keys(s_selectHub).length > 0 ? (
    s_selectHub.setidm === 'S' ? '電腦鍵盤輸入' : '現場掃碼入庫'
  ) : '';

  return(
    <>
      <Form.Provider
          // 如果是否離職選擇 '否' 要清除離職時間
          onFormChange={(formName, info) => {
            const { changedFields } = info;
            if(changedFields[0].name[0] === 'Hub') {
              const data = s_AllHub.filter(item => item.UUID === changedFields[0].value);
              console.log('data = ', data)
              set_s_selectHub(data)
            }
            // if(changedFields[0].name[0] === 'isUnSite') {
            //   if(changedFields[0].value === 'N') {
            //     form.setFieldsValue({quitT: null})
            //   }
            //   set_s_isUnSite(changedFields[0].value)
            // }
          }}
        >
          <StyledForm
            name="basic"
            form={form}
            initialValues={{
              // opcodeID: s_PlainInData.opcodeID,
              whID: s_PlainInData.whID,
              Hub: s_PlainInData.Hub
            }}
            onFinish={onSubmit}
            autoComplete="off"
            layout="vertical"
            
            // layout="horizontal"
          >
            <Row gutter={[24, 24]}>
              {/* <Col span={8}>
                <Form.Item 
                  label={<label style={{color: '#6C6C6C'}}>入庫別</label>} 
                  name="opcodeID" 
                  rules={[{ required: true, message: '請選擇入庫別!' }]}
                >
                  <Select placeholder="選擇入庫別" allowClear >
                    <Option value={4110}>生產入庫</Option>
                    <Option value={4120}>採購入庫</Option>
                    <Option value={4130}>其他入庫</Option>
                  </Select>
                </Form.Item>
              </Col> */}

              <Col span={8}>
                <Form.Item 
                  label={<label style={{color: '#6C6C6C'}}>庫房</label>} 
                  name="whID" 
                  rules={[{ required: true, message: '請選擇庫房!' }]}
                >
                  <Select placeholder="選擇庫房" allowClear >
                    {s_AllWh.length > 0 ? (
                      s_AllWh.map((item, index) => <Option key={index} value={item.whID}>{item.whNM}</Option>)
                    ) : null}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item 
                  label={<label style={{color: '#6C6C6C'}}>入庫單</label>} 
                  name="Hub" 
                  rules={[{ required: true, message: '請選擇入庫單!' }]}
                >
                  <Select 
                    placeholder="選擇入庫單" 
                    // allowClear 
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {s_AllHub.length > 0 ? (
                      s_AllHub.map((item, index) => <Option key={index} value={item.UUID}>{item.woN}</Option>)
                    ) : null}
                  </Select>
                </Form.Item>
              </Col>

              {/* <Row gutter={[24, 24]} align="middle">
              <Col span={1}>型態</Col>
            </Row>

            <Row gutter={[24, 24]} align="middle" style={{marginBottom: '20px'}}>
              <Col span={8}>
                  <Input value={typeVal} disabled />
              </Col>
            </Row> */}
              <Col span={8}>
                <Row gutter={[24, 24]}>
                  <Col span={4} style={{paddingBottom: '8px'}}>型態</Col>
                </Row>
                <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Input value={typeVal} disabled />
                  </Col>
                </Row>
              </Col>

            </Row>

            

            <HubTab s_selectHub={s_selectHub} />

            <Divider style={{marginBottom: '0px'}} />

            <Row gutter={[24, 24]} style={{marginBottom: '20px'}}>
              <Col span={8}>
                {/* <Row>
                  {Object.keys(s_initialValues).length > 0 ? (
                    <Col offset={2} style={{display: 'none'}}>
                      <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={() => alert('施工中')}>
                        據此創建新條目
                      </Button>
                    </Col>
                  ) : null}
                </Row> */}
              </Col>
              <Col offset={8} span={8}>
                <div style={{display: 'flex',justifyContent: 'flex-end', marginTop: '20px'}}>
                  <Button size='large' style={{marginRight: '20px', borderRadius: '5px'}} onClick={handleCancel}>
                    取消
                  </Button>
                  <Form.Item>
                    <Button size='large' type="primary" htmlType="submit" style={{borderRadius: '5px'}}>
                      確定
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
            

          </StyledForm>
      </Form.Provider>
      
    </>
  )
};

export default ModalContainer;
