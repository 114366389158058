import { numberWithCommas } from "./numberWithCommas.js";
// checkUndefined

const checkUndefined = (variable) => {
  if (
    variable === undefined ||
    variable === null ||
    Array.isArray(variable) === true
  ) {
    return "暫無數據";
  }
  switch (typeof variable) {
    case "string":
      if (variable === "" || variable === "0") {
        return "暫無數據";
      } else {
        return variable;
      }
    case "number":
      return numberWithCommas(variable);
    default:
      return "暫無數據";
  }
};

export default checkUndefined;
