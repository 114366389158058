import React, { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import { Progress } from "antd";
/**
 * description
 * /**
 * @param {number} param - percent
 */

const LineChartElement = ({ className, percent=0 }) => {
  const theme = useTheme();
  return (
    <div className={className}>
      <Progress
        showInfo={false}
        status="active"
        strokeColor={{
          "0%": theme.mainColor,
          "100%": theme.Color2,
        }}
        strokeWidth={28}
        percent={percent}
      />
      <span className="line-chart-percent">{percent + "%"}</span>
    </div>
  );
};

const LineChart = styled(LineChartElement)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .line-chart-percent {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    color: ${(props) =>
      props.percent <= 50 ? props.theme.text : props.theme.retext};
    font-weight: 500;
  }
`;

export default LineChart;
