import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { Table, Button } from "antd";
import { fontSize } from "../../styled/publicTag";
import { getAllPS } from "../../services/api";
import useWebSocket from "../../utility/useWebSocket_new";
import { PlusOutlined } from '@ant-design/icons'
import { url } from "../../services/handlerAPI";
import CustomModal from '../../components/antdCustom/CustomModal';

import Light from './Light';

import Title from '../../components/Custom/Title';
import ModalContainer from './ModalContainer';
import DashBox from './DashBox'

import moment from "moment";

import { Select } from 'antd';

const innerWidth = window.innerWidth;

const Wrapper = styled.div`
  padding: 10px 20px 0px 25px;
  background-color: ${({ theme }) => theme.mainBg};
`
// const Title = styled.div`
//   color: ${({ theme }) => theme.Color3};
//   font-size: ${fontSize.h3};
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `
const CustomTable = styled(Table)`
  //表格內容
  .ant-table-tbody .ant-table-cell {
    padding: 4px;
  }

  // table 顯示頁碼的地方
  .ant-pagination-total-text h4 {
    font-size: ${fontSize.p};
  }
  
  .ant-table-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  //table 的圓角
  .ant-table-container, .ant-table {
    border-radius: 10px!important;
  }

  /* width */
  .ant-table-body::-webkit-scrollbar {
    width: 10px!important;
  }
  
  // 讓滾動條下半部不要擋住table的圓角
  .ant-table-body::-webkit-scrollbar-thumb {
    background-color: #e5e5e5;
    border-radius: 10px;
  }

  .ant-table-thead .ant-table-cell { 
    padding: 8px;
    /* font-size: ${fontSize.text}; */
    font-size: ${({size}) => size ? size : fontSize.text};
    white-space: nowrap;
  }

  .size {
    font-size: ${innerWidth <= 1440 ? '12px' : '16px'}
  }

  // 使用字級 + 4px
  .lineHeight {
    line-height: 25px;
  }

  .left {
    width: 100%;
    text-align: left;
  }
`

function DashBoard() {

  const innerWidth = window.innerWidth;

  const [tableData, setTableData] = useState([]);

  const [s_showModal, set_s_showModal] = useState(false); // 是否開啟 dialog

  const [s_boxData, set_s_boxData] = useState({});

  const [s_AllStation_options, set_s_AllStation_options] = useState([]); // 所有工站

  const [s_psID, set_s_psID] = useState('');

  const [dashboardDataSocket, changeDashboardDataParams, setDashboardData] = useWebSocket();

  useEffect(() => {
    let search = window.location.search
    const urlParams = new URLSearchParams(search);
    console.log('urlParams ============== ', urlParams.get('psID'))
    
    
    changeDashboardDataParams({
      url: url,
      apiName: "dashboard/socket",
      params: `psID=${urlParams.get('psID') ? urlParams.get('psID') : ''}`
    });
    setDashboardData(true);
  }, [s_psID]);

  // 第一次渲染取得所有車間資料, 並塞值給 s_searchData
  useEffect(() => {
    const call_getAllPS = async () => {
      try {
        const res = await getAllPS();
        if(res.status === true && res.data.length > 0) {
          console.log('全部車間資料 = ', res.data);
          let options = res.data.map(item => <Select.Option key={item.psID} value={item.psID}>{item.psNM}</Select.Option>);
          options.push(<Select.Option key='index' value=''>全部車間</Select.Option>)
          set_s_AllStation_options(options.reverse());

          // if(res.data.length > 0) {
          //   set_s_searchData({
          //     ...s_searchData,
          //     'wsID': res.data[0].wsID
          //   });
          // };
          // set_s_psID(res.data[0].psID)
          let search = window.location.search
          const urlParams = new URLSearchParams(search);
          if(urlParams.get('psID')) {
            set_s_psID(urlParams.get('psID'))
          }
        }
        
      } catch (e) {
        console.log('---------- api getAllStaffPerm error ----------');
        console.log(e)
      }
    }
    call_getAllPS();
  }, []);

  useEffect(() => {
    if (dashboardDataSocket.status) {
      //message.success("看板更新成功");
      console.log(dashboardDataSocket.data)
      
      const aa = [
        {
            "UUID": "",
            "wsID": "",
            "wlID": "D02",
            "woN": "",
            "wotype": 0,
            "wpcset": null,
            "wpcSN": "",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 0,
            "d_planqty": 0,
            "inC": 0,
            "d_inC": 0,
            "ngC": 0,
            "okC": 0,
            "bal": 0,
            "moldcavity": 0,
            "hdC": 0,
            "whC": 0,
            "mhC": 0,
            "situF": "W",
            "situFT": "0001-01-01T00:00:00Z",
            "lifeF": "",
            "lifeFT": "0001-01-01T00:00:00Z",
            "openT": "0001-01-01T00:00:00Z",
            "prepareT": "0001-01-01T00:00:00Z",
            "closeT": "0001-01-01T00:00:00Z",
            "PN": "",
            "pdtNM": "",
            "sliceSN": 0,
            "sliceT": "2022-01-25T14:04:41+08:00",
            "PPE": 0,
            "planTAR": 0,
            "emhC": 0,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "",
            "newlifeFT": "",
            "newopenT": "",
            "newprepareT": "",
            "newcloseT": "",
            "newsliceT": "01-25 14:04",
            "target": 0,
            "mappedLifeF": "",
            "brand": "",
            "model": "",
            "wlNM": "DC304",
            "pdtRate": "0.0%",
            "allPdtRate": "0.0%",
            "ngRate": "0.0%",
            "allInC": 0,
            "allPlanqty": 0,
            "lastInC": 0,
            "pdtC": 0,
            "cycletimemh": 0,
            "moldID": ""
        },
        {
            "UUID": "4f315dba-39fa-4146-bd9c-b189bcc2e848",
            "wsID": "WS01",
            "wlID": "D03",
            "woN": "SD2202021",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 5000,
            "d_planqty": 0,
            "inC": 106,
            "d_inC": 0,
            "ngC": 0,
            "okC": 0,
            "bal": 0,
            "moldcavity": 1,
            "hdC": 1,
            "whC": 5490,
            "mhC": 1.5475,
            "situF": "Y",
            "situFT": "2022-02-14T09:33:09+08:00",
            "lifeF": "2",
            "lifeFT": "2022-02-14T08:02:16+08:00",
            "openT": "2022-02-14T08:02:16+08:00",
            "prepareT": "2022-02-14T08:01:12+08:00",
            "closeT": "0001-01-01T00:00:00Z",
            "PN": "G6A.RA08A0D.11G01",
            "pdtNM": "RA08A00D1 Body-Dbl Pilot-Mach Dwg.62O (壓鑄) ADC12 本色 RA CF4",
            "sliceSN": 85,
            "sliceT": "2022-02-14T09:34:07+08:00",
            "PPE": 72.3,
            "planTAR": 0,
            "emhC": 1.5138888888888888,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "02-14 09:33",
            "newlifeFT": "02-14 08:02",
            "newopenT": "02-14 08:02",
            "newprepareT": "02-14 08:01",
            "newcloseT": "",
            "newsliceT": "02-14 09:34",
            "target": 5000,
            "mappedLifeF": "開始生產",
            "brand": "",
            "model": "",
            "wlNM": "DC302",
            "pdtRate": "2.1%",
            "allPdtRate": "2.1%",
            "ngRate": "0.0%",
            "allInC": 107,
            "allPlanqty": 5000,
            "lastInC": 4893,
            "pdtC": 107,
            "cycletimemh": 38,
            "moldID": "RA08AG01"
        },
        {
            "UUID": "028ae558-7b62-4fa5-8e12-4be33b8acb6e",
            "wsID": "WS01",
            "wlID": "D04",
            "woN": "SD2202005",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 8148,
            "d_planqty": 0,
            "inC": 118,
            "d_inC": 0,
            "ngC": 0,
            "okC": 0,
            "bal": 0,
            "moldcavity": 1,
            "hdC": 1,
            "whC": 5396,
            "mhC": 1.508888888888889,
            "situF": "Y",
            "situFT": "2022-02-14T09:33:09+08:00",
            "lifeF": "2",
            "lifeFT": "2022-02-14T08:03:40+08:00",
            "openT": "2022-02-14T08:03:40+08:00",
            "prepareT": "2022-02-14T08:03:31+08:00",
            "closeT": "0001-01-01T00:00:00Z",
            "PN": "G6A.YA08A0D.11G01",
            "pdtNM": "YA08A00D0 HSG (壓鑄) ADC12 本色 YA X0L",
            "sliceSN": 84,
            "sliceT": "2022-02-14T09:34:07+08:00",
            "PPE": 80.4,
            "planTAR": 0,
            "emhC": 1.4905555555555556,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "02-14 09:33",
            "newlifeFT": "02-14 08:03",
            "newopenT": "02-14 08:03",
            "newprepareT": "02-14 08:03",
            "newcloseT": "",
            "newsliceT": "02-14 09:34",
            "target": 8148,
            "mappedLifeF": "開始生產",
            "brand": "",
            "model": "",
            "wlNM": "DC303",
            "pdtRate": "1.4%",
            "allPdtRate": "36.3%",
            "ngRate": "0.0%",
            "allInC": 4572,
            "allPlanqty": 12600,
            "lastInC": 8028,
            "pdtC": 120,
            "cycletimemh": 37,
            "moldID": "YA08AG01"
        },
        {
            "UUID": "",
            "wsID": "",
            "wlID": "D05",
            "woN": "",
            "wotype": 0,
            "wpcset": null,
            "wpcSN": "",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 0,
            "d_planqty": 0,
            "inC": 0,
            "d_inC": 0,
            "ngC": 0,
            "okC": 0,
            "bal": 0,
            "moldcavity": 0,
            "hdC": 0,
            "whC": 0,
            "mhC": 0,
            "situF": "W",
            "situFT": "0001-01-01T00:00:00Z",
            "lifeF": "",
            "lifeFT": "0001-01-01T00:00:00Z",
            "openT": "0001-01-01T00:00:00Z",
            "prepareT": "0001-01-01T00:00:00Z",
            "closeT": "0001-01-01T00:00:00Z",
            "PN": "",
            "pdtNM": "",
            "sliceSN": 0,
            "sliceT": "2022-02-10T13:29:03+08:00",
            "PPE": 0,
            "planTAR": 0,
            "emhC": 0,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "",
            "newlifeFT": "",
            "newopenT": "",
            "newprepareT": "",
            "newcloseT": "",
            "newsliceT": "02-10 13:29",
            "target": 0,
            "mappedLifeF": "",
            "brand": "",
            "model": "",
            "wlNM": "DC101",
            "pdtRate": "0.0%",
            "allPdtRate": "0.0%",
            "ngRate": "0.0%",
            "allInC": 0,
            "allPlanqty": 0,
            "lastInC": 0,
            "pdtC": 0,
            "cycletimemh": 0,
            "moldID": ""
        },
        {
            "UUID": "bcecd7ed-700d-4c29-9ef5-8fefd46064da",
            "wsID": "WS01",
            "wlID": "D06",
            "woN": "SD2202024",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 1500,
            "d_planqty": 0,
            "inC": 0,
            "d_inC": 0,
            "ngC": 0,
            "okC": 0,
            "bal": 0,
            "moldcavity": 1,
            "hdC": 0,
            "whC": 0,
            "mhC": 0,
            "situF": "B",
            "situFT": "2022-02-14T09:02:29+08:00",
            "lifeF": "B",
            "lifeFT": "2022-02-14T09:02:29+08:00",
            "openT": "0001-01-01T00:00:00Z",
            "prepareT": "2022-02-14T09:02:29+08:00",
            "closeT": "0001-01-01T00:00:00Z",
            "PN": "G6A.HN13A0D.11G01",
            "pdtNM": "HN13A00D3 Casting Hsls2 (壓鑄) ADC12 本色 HN HSLS2",
            "sliceSN": 0,
            "sliceT": "2022-02-14T09:02:10+08:00",
            "PPE": 0,
            "planTAR": 0,
            "emhC": 0,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "02-14 09:02",
            "newlifeFT": "02-14 09:02",
            "newopenT": "",
            "newprepareT": "02-14 09:02",
            "newcloseT": "",
            "newsliceT": "02-14 09:02",
            "target": 1500,
            "mappedLifeF": "上模中",
            "brand": "",
            "model": "",
            "wlNM": "DC102",
            "pdtRate": "0.0%",
            "allPdtRate": "0.0%",
            "ngRate": "0.0%",
            "allInC": 0,
            "allPlanqty": 1500,
            "lastInC": -15,
            "pdtC": 0,
            "cycletimemh": 28,
            "moldID": ""
        },
        {
            "UUID": "",
            "wsID": "",
            "wlID": "D07",
            "woN": "",
            "wotype": 0,
            "wpcset": null,
            "wpcSN": "",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 0,
            "d_planqty": 0,
            "inC": 0,
            "d_inC": 0,
            "ngC": 0,
            "okC": 0,
            "bal": 0,
            "moldcavity": 0,
            "hdC": 0,
            "whC": 0,
            "mhC": 0,
            "situF": "W",
            "situFT": "0001-01-01T00:00:00Z",
            "lifeF": "",
            "lifeFT": "0001-01-01T00:00:00Z",
            "openT": "0001-01-01T00:00:00Z",
            "prepareT": "0001-01-01T00:00:00Z",
            "closeT": "0001-01-01T00:00:00Z",
            "PN": "",
            "pdtNM": "",
            "sliceSN": 0,
            "sliceT": "2022-02-09T19:48:10+08:00",
            "PPE": 0,
            "planTAR": 0,
            "emhC": 0,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "",
            "newlifeFT": "",
            "newopenT": "",
            "newprepareT": "",
            "newcloseT": "",
            "newsliceT": "02-09 19:48",
            "target": 0,
            "mappedLifeF": "",
            "brand": "",
            "model": "",
            "wlNM": "DC107",
            "pdtRate": "0.0%",
            "allPdtRate": "0.0%",
            "ngRate": "0.0%",
            "allInC": 0,
            "allPlanqty": 0,
            "lastInC": 0,
            "pdtC": 0,
            "cycletimemh": 0,
            "moldID": ""
        },
        {
            "UUID": "c2ab529b-f7be-4183-9c72-38348b2f8359",
            "wsID": "WS01",
            "wlID": "D08",
            "woN": "SD2202022",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 42000,
            "d_planqty": 0,
            "inC": 230,
            "d_inC": 0,
            "ngC": 0,
            "okC": 0,
            "bal": 0,
            "moldcavity": 2,
            "hdC": 1,
            "whC": 2544,
            "mhC": 0.7213888888888889,
            "situF": "G",
            "situFT": "2022-02-14T09:33:09+08:00",
            "lifeF": "2",
            "lifeFT": "2022-02-14T08:50:53+08:00",
            "openT": "2022-02-14T08:50:53+08:00",
            "prepareT": "2022-02-14T08:50:46+08:00",
            "closeT": "0001-01-01T00:00:00Z",
            "PN": "G6A.NT09A0D.11G01",
            "pdtNM": "NT09A00D1 底蓋 (壓鑄+折壓) ADC12 本色 NT Apd",
            "sliceSN": 40,
            "sliceT": "2022-02-14T09:34:07+08:00",
            "PPE": 97.4,
            "planTAR": 0,
            "emhC": 0.7036111111111111,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "02-14 09:33",
            "newlifeFT": "02-14 08:50",
            "newopenT": "02-14 08:50",
            "newprepareT": "02-14 08:50",
            "newcloseT": "",
            "newsliceT": "02-14 09:34",
            "target": 42000,
            "mappedLifeF": "開始生產",
            "brand": "",
            "model": "",
            "wlNM": "DC108",
            "pdtRate": "0.5%",
            "allPdtRate": "0.6%",
            "ngRate": "0.0%",
            "allInC": 236,
            "allPlanqty": 42000,
            "lastInC": 10,
            "pdtC": 118,
            "cycletimemh": 11,
            "moldID": "NT09AG02"
        },
        {
            "UUID": "",
            "wsID": "",
            "wlID": "D09",
            "woN": "",
            "wotype": 0,
            "wpcset": null,
            "wpcSN": "",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 0,
            "d_planqty": 0,
            "inC": 0,
            "d_inC": 0,
            "ngC": 0,
            "okC": 0,
            "bal": 0,
            "moldcavity": 0,
            "hdC": 0,
            "whC": 0,
            "mhC": 0,
            "situF": "W",
            "situFT": "0001-01-01T00:00:00Z",
            "lifeF": "",
            "lifeFT": "0001-01-01T00:00:00Z",
            "openT": "0001-01-01T00:00:00Z",
            "prepareT": "0001-01-01T00:00:00Z",
            "closeT": "0001-01-01T00:00:00Z",
            "PN": "",
            "pdtNM": "",
            "sliceSN": 0,
            "sliceT": "2022-02-12T16:31:23+08:00",
            "PPE": 0,
            "planTAR": 0,
            "emhC": 0,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "",
            "newlifeFT": "",
            "newopenT": "",
            "newprepareT": "",
            "newcloseT": "",
            "newsliceT": "02-12 16:31",
            "target": 0,
            "mappedLifeF": "",
            "brand": "",
            "model": "",
            "wlNM": "DC201",
            "pdtRate": "0.0%",
            "allPdtRate": "0.0%",
            "ngRate": "0.0%",
            "allInC": 0,
            "allPlanqty": 0,
            "lastInC": 0,
            "pdtC": 0,
            "cycletimemh": 0,
            "moldID": ""
        },
        {
            "UUID": "",
            "wsID": "",
            "wlID": "D10",
            "woN": "",
            "wotype": 0,
            "wpcset": null,
            "wpcSN": "",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 0,
            "d_planqty": 0,
            "inC": 0,
            "d_inC": 0,
            "ngC": 0,
            "okC": 0,
            "bal": 0,
            "moldcavity": 0,
            "hdC": 0,
            "whC": 0,
            "mhC": 0,
            "situF": "W",
            "situFT": "0001-01-01T00:00:00Z",
            "lifeF": "",
            "lifeFT": "0001-01-01T00:00:00Z",
            "openT": "0001-01-01T00:00:00Z",
            "prepareT": "0001-01-01T00:00:00Z",
            "closeT": "0001-01-01T00:00:00Z",
            "PN": "",
            "pdtNM": "",
            "sliceSN": 0,
            "sliceT": "2022-02-12T19:11:56+08:00",
            "PPE": 0,
            "planTAR": 0,
            "emhC": 0,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "",
            "newlifeFT": "",
            "newopenT": "",
            "newprepareT": "",
            "newcloseT": "",
            "newsliceT": "02-12 19:11",
            "target": 0,
            "mappedLifeF": "",
            "brand": "",
            "model": "",
            "wlNM": "DC202",
            "pdtRate": "0.0%",
            "allPdtRate": "0.0%",
            "ngRate": "0.0%",
            "allInC": 0,
            "allPlanqty": 0,
            "lastInC": 0,
            "pdtC": 0,
            "cycletimemh": 0,
            "moldID": ""
        },
        {
            "UUID": "c50eff38-79c5-42c8-b3d8-cba573f9b5b8",
            "wsID": "WS01",
            "wlID": "D11",
            "woN": "SD2202020",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 12734,
            "d_planqty": 0,
            "inC": 342,
            "d_inC": 0,
            "ngC": 0,
            "okC": 0,
            "bal": 0,
            "moldcavity": 2,
            "hdC": 1,
            "whC": 5125,
            "mhC": 1.4344444444444444,
            "situF": "Y",
            "situFT": "2022-02-14T09:33:09+08:00",
            "lifeF": "2",
            "lifeFT": "2022-02-14T08:08:07+08:00",
            "openT": "2022-02-14T08:08:07+08:00",
            "prepareT": "2022-02-14T08:07:59+08:00",
            "closeT": "0001-01-01T00:00:00Z",
            "PN": "G6A.YA09A0D.11G01",
            "pdtNM": "YA09A00D0 Cover Stator (壓鑄) ADC12 本色 YA X0L",
            "sliceSN": 80,
            "sliceT": "2022-02-14T09:34:07+08:00",
            "PPE": 86.1,
            "planTAR": 0,
            "emhC": 1.416388888888889,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "02-14 09:33",
            "newlifeFT": "02-14 08:08",
            "newopenT": "02-14 08:08",
            "newprepareT": "02-14 08:07",
            "newcloseT": "",
            "newsliceT": "02-14 09:34",
            "target": 12734,
            "mappedLifeF": "開始生產",
            "brand": "",
            "model": "",
            "wlNM": "DC203",
            "pdtRate": "2.7%",
            "allPdtRate": "38.0%",
            "ngRate": "0.0%",
            "allInC": 7608,
            "allPlanqty": 20000,
            "lastInC": 12392,
            "pdtC": 171,
            "cycletimemh": 13,
            "moldID": "YA09AG01"
        },
        {
            "UUID": "",
            "wsID": "",
            "wlID": "D12",
            "woN": "",
            "wotype": 0,
            "wpcset": null,
            "wpcSN": "",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 0,
            "d_planqty": 0,
            "inC": 0,
            "d_inC": 0,
            "ngC": 0,
            "okC": 0,
            "bal": 0,
            "moldcavity": 0,
            "hdC": 0,
            "whC": 0,
            "mhC": 0,
            "situF": "W",
            "situFT": "0001-01-01T00:00:00Z",
            "lifeF": "",
            "lifeFT": "0001-01-01T00:00:00Z",
            "openT": "0001-01-01T00:00:00Z",
            "prepareT": "0001-01-01T00:00:00Z",
            "closeT": "0001-01-01T00:00:00Z",
            "PN": "",
            "pdtNM": "",
            "sliceSN": 0,
            "sliceT": "2022-02-11T11:49:52+08:00",
            "PPE": 0,
            "planTAR": 0,
            "emhC": 0,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "",
            "newlifeFT": "",
            "newopenT": "",
            "newprepareT": "",
            "newcloseT": "",
            "newsliceT": "02-11 11:49",
            "target": 0,
            "mappedLifeF": "",
            "brand": "",
            "model": "",
            "wlNM": "DC204",
            "pdtRate": "0.0%",
            "allPdtRate": "0.0%",
            "ngRate": "0.0%",
            "allInC": 0,
            "allPlanqty": 0,
            "lastInC": 0,
            "pdtC": 0,
            "cycletimemh": 0,
            "moldID": ""
        },
        {
            "UUID": "",
            "wsID": "",
            "wlID": "D13",
            "woN": "",
            "wotype": 0,
            "wpcset": null,
            "wpcSN": "",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 0,
            "d_planqty": 0,
            "inC": 0,
            "d_inC": 0,
            "ngC": 0,
            "okC": 0,
            "bal": 0,
            "moldcavity": 0,
            "hdC": 0,
            "whC": 0,
            "mhC": 0,
            "situF": "W",
            "situFT": "0001-01-01T00:00:00Z",
            "lifeF": "",
            "lifeFT": "0001-01-01T00:00:00Z",
            "openT": "0001-01-01T00:00:00Z",
            "prepareT": "0001-01-01T00:00:00Z",
            "closeT": "0001-01-01T00:00:00Z",
            "PN": "",
            "pdtNM": "",
            "sliceSN": 0,
            "sliceT": "2022-01-27T19:37:03+08:00",
            "PPE": 0,
            "planTAR": 0,
            "emhC": 0,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "",
            "newlifeFT": "",
            "newopenT": "",
            "newprepareT": "",
            "newcloseT": "",
            "newsliceT": "01-27 19:37",
            "target": 0,
            "mappedLifeF": "",
            "brand": "",
            "model": "",
            "wlNM": "DC801",
            "pdtRate": "0.0%",
            "allPdtRate": "0.0%",
            "ngRate": "0.0%",
            "allInC": 0,
            "allPlanqty": 0,
            "lastInC": -10,
            "pdtC": 0,
            "cycletimemh": 0,
            "moldID": ""
        },
        {
            "UUID": "7cb96fbf-d972-4454-b0da-5e547cc69361",
            "wsID": "WS01",
            "wlID": "D14",
            "woN": "SD2202004",
            "wotype": 0,
            "wpcset": "0",
            "wpcSN": "1",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 5211,
            "d_planqty": 0,
            "inC": 57,
            "d_inC": 0,
            "ngC": 0,
            "okC": 0,
            "bal": 0,
            "moldcavity": 1,
            "hdC": 2,
            "whC": 10043,
            "mhC": 1.4072222222222222,
            "situF": "Y",
            "situFT": "2022-02-14T09:33:09+08:00",
            "lifeF": "2",
            "lifeFT": "2022-02-14T08:09:44+08:00",
            "openT": "2022-02-14T08:09:44+08:00",
            "prepareT": "2022-02-14T08:09:37+08:00",
            "closeT": "0001-01-01T00:00:00Z",
            "PN": "G6A.PL17A0D.11G01",
            "pdtNM": "PL17A00D1 釘槍 (壓鑄+鋸切) ADC12 本色 PL 32A",
            "sliceSN": 78,
            "sliceT": "2022-02-14T09:34:07+08:00",
            "PPE": 56.3,
            "planTAR": 0,
            "emhC": 1.3894444444444445,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "02-14 09:33",
            "newlifeFT": "02-14 08:09",
            "newopenT": "02-14 08:09",
            "newprepareT": "02-14 08:09",
            "newcloseT": "",
            "newsliceT": "02-14 09:34",
            "target": 5211,
            "mappedLifeF": "開始生產",
            "brand": "",
            "model": "",
            "wlNM": "DC601",
            "pdtRate": "1.1%",
            "allPdtRate": "28.4%",
            "ngRate": "0.0%",
            "allInC": 2047,
            "allPlanqty": 7200,
            "lastInC": 5153,
            "pdtC": 58,
            "cycletimemh": 50,
            "moldID": "PL17AG01"
        },
        {
            "UUID": "",
            "wsID": "",
            "wlID": "D15",
            "woN": "",
            "wotype": 0,
            "wpcset": null,
            "wpcSN": "",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 0,
            "d_planqty": 0,
            "inC": 0,
            "d_inC": 0,
            "ngC": 0,
            "okC": 0,
            "bal": 0,
            "moldcavity": 0,
            "hdC": 0,
            "whC": 0,
            "mhC": 0,
            "situF": "W",
            "situFT": "0001-01-01T00:00:00Z",
            "lifeF": "",
            "lifeFT": "0001-01-01T00:00:00Z",
            "openT": "0001-01-01T00:00:00Z",
            "prepareT": "0001-01-01T00:00:00Z",
            "closeT": "0001-01-01T00:00:00Z",
            "PN": "",
            "pdtNM": "",
            "sliceSN": 0,
            "sliceT": "2022-01-19T19:41:50+08:00",
            "PPE": 0,
            "planTAR": 0,
            "emhC": 0,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "",
            "newlifeFT": "",
            "newopenT": "",
            "newprepareT": "",
            "newcloseT": "",
            "newsliceT": "01-19 19:41",
            "target": 0,
            "mappedLifeF": "",
            "brand": "",
            "model": "",
            "wlNM": "MD601",
            "pdtRate": "0.0%",
            "allPdtRate": "0.0%",
            "ngRate": "0.0%",
            "allInC": 0,
            "allPlanqty": 0,
            "lastInC": -100,
            "pdtC": 0,
            "cycletimemh": 0,
            "moldID": ""
        },
        {
            "UUID": "",
            "wsID": "",
            "wlID": "D16",
            "woN": "",
            "wotype": 0,
            "wpcset": null,
            "wpcSN": "",
            "addSN": 0,
            "prefix": 0,
            "suffix": 0,
            "planqty": 0,
            "d_planqty": 0,
            "inC": 0,
            "d_inC": 0,
            "ngC": 0,
            "okC": 0,
            "bal": 0,
            "moldcavity": 0,
            "hdC": 0,
            "whC": 0,
            "mhC": 0,
            "situF": "W",
            "situFT": "0001-01-01T00:00:00Z",
            "lifeF": "",
            "lifeFT": "0001-01-01T00:00:00Z",
            "openT": "0001-01-01T00:00:00Z",
            "prepareT": "0001-01-01T00:00:00Z",
            "closeT": "0001-01-01T00:00:00Z",
            "PN": "",
            "pdtNM": "",
            "sliceSN": 0,
            "sliceT": "2022-01-25T15:07:21+08:00",
            "PPE": 0,
            "planTAR": 0,
            "emhC": 0,
            "ePPE": 0,
            "n_planqty": 0,
            "n_inC": 0,
            "matl": null,
            "shift": null,
            "newsituFT": "",
            "newlifeFT": "",
            "newopenT": "",
            "newprepareT": "",
            "newcloseT": "",
            "newsliceT": "01-25 15:07",
            "target": 0,
            "mappedLifeF": "",
            "brand": "",
            "model": "",
            "wlNM": "MD308",
            "pdtRate": "0.0%",
            "allPdtRate": "0.0%",
            "ngRate": "0.0%",
            "allInC": 0,
            "allPlanqty": 0,
            "lastInC": 0,
            "pdtC": 0,
            "cycletimemh": 0,
            "moldID": ""
        }
    ]
      
      setTableData(sortData(dashboardDataSocket.data));
      // setTableData(sortData(aa));

      if(dashboardDataSocket.data && dashboardDataSocket.data.length > 0) {
        // 開線數
        const openLine = dashboardDataSocket.data.filter(item => item.situF !== 'W').length;
        // 總線數
        const allLine = dashboardDataSocket.data.length;
        // 開線率
        const openLineRate = `${(openLine/allLine*100).toFixed(1)}%`;

        set_s_boxData({ openLine, allLine, openLineRate });
      }

    } else {
      set_s_boxData({});
      //message.error("看板更新失敗");
    }
    console.log('看板資料 = ', dashboardDataSocket);
  }, [dashboardDataSocket]);

  const sortData = (data) => {
    try {
      const newData = data.map((x) => {
        return {
          ...x,
          openT: sortTime(x.openT),
          closeT: sortTime(x.closeT),
          lifeFT: sortTime(x.lifeFT),
          sliceT: sortTime(x.sliceT),
          mhC: formatFloat(x.mhC, 1),
        };
      });
      return newData;
    } catch (e) {
      console.log('------------ sortData error ------------');
      return [];
    }
  };

  function sortTime(time) {
    var newTime = moment(time).format("YYYY-MM-DD HH:mm:ss");
    if (moment(newTime).year() < 2000) {
      return null;
    } else {
      return newTime;
    }
  }

  const handleChange = (value) => {
    set_s_psID(value);
    // const urlParams = new URLSearchParams(window.location.search);
    // console.log('urlParams = ', urlParams)
    // urlParams.set('order', 'date');
    // window.location.search = urlParams;

    var url = `${window.location.pathname}?psID=${value}`
    window.history.pushState({url: url}, '', url)
  }

  function formatFloat(num, pos) {
    var size = Math.pow(10, pos);
    return Math.round(num * size) / size;
  }

  const tableColumns = [
    {
      title: "線別",
      dataIndex: "wlID",
      key: "wlID",
      className: 'lineHeight',
      align: 'center',
      //width: 100,
      width: innerWidth <= 1440 ? `${60}px` : `${100}px`,
      render: (text, rowData, index) => {
        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? '8px' : null }
          },
          children: text
        };
      }
    },
    {
      title: "機台編號",
      dataIndex: "wlNM",
      key: "wlNM",
      className: 'lineHeight',
      align: 'center',
      //width: 100,
      width: innerWidth <= 1440 ? `${60}px` : `${100}px`,
      render: (text, rowData, index) => {
        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? '8px' : null }
          },
          children: text
        };
      }
    },
    {
      title: "狀態",
      dataIndex: "situF",
      key: "situF",
      className: 'lineHeight',
      align: 'center',
      width: innerWidth <= 1440 ? `${60}px` : `${80}px`,
      render: (value, recode) => {
        const colorMap = { 
          "W": 'white', 
          "R": "red", 
          "G": "rgb(54, 230, 28)", 
          "Y": "yellow",
          "B": 'blue' 
        };

        return recode.situF === 'W' ? (
          showLight(colorMap[recode.situF])
        ) : <Light color={recode.situF} size='17px' />
        // console.log('recode.situF = ', recode.situF)
        // //if(colorMap[recode.situF]) alert(colorMap[recode.situF])
				// return showLight(colorMap[recode.situF])
			},
    },
    {
      title: "模具編號",
      dataIndex: "moldID",
      key: "moldID",
      className: 'lineHeight',
      align: 'center',
      //width: 100,
      width: innerWidth <= 1440 ? `${60}px` : `${100}px`,
      render: (text, rowData, index) => {
        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? '8px' : null }
          },
          children: rowData.woN === '' ? '' : text
        };
      }
    },
    {
      title: "工單號",
      dataIndex: "woN",
      key: "woN",
      className: 'lineHeight',
      align: 'center',
      render: (text, rowData, index) => {
        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? '8px' : null }
          },
          children: text
        };
      }
    },
    {
      title: "料號",
      dataIndex: "PN",
      key: "PN",
      className: 'lineHeight',
      align: 'center',
      render: (text, rowData, index) => {
        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? '8px' : null }
          },
          children: text
        };
      },
      // width: 160,
      width: innerWidth <= 1440 ? `${180}px` : `${200}px`
    },
    // {
    //   title: '達成率',
    //   width: innerWidth <= 1440 ? `${60}px` : `${100}px`,
    //   align: 'center',
    //   className: 'lineHeight',
    //   dataIndex: "pdtRate",
    //   key: "pdtRate",
    //   render: (text, rowData, index) => {
    //     return {
    //       props: {
    //         style: { fontSize: innerWidth <= 1440 ? '8px' : null }
    //       },
    //       children: text
    //     };
    //   },
    // },
    // {
    //   title: '不良率',
    //   width: innerWidth <= 1440 ? `${60}px` : `${100}px`,
    //   align: 'center',
    //   className: 'lineHeight',
    //   dataIndex: "ngRate",
    //   key: "ngRate",
    //   render: (text, rowData, index) => {
    //     return {
    //       props: {
    //         style: { fontSize: innerWidth <= 1440 ? '8px' : null }
    //       },
    //       children: text
    //     };
    //   },
    // },
    {
      title: "計畫產量",
      // title: <span>目標<br />產量</span>,
      width: innerWidth <= 1440 ? `${60}px` : `${100}px`,
      align: 'center',
      className: 'lineHeight',
      dataIndex: "allPlanqty",
      key: "allPlanqty",
      render: (text, rowData, index) => {

        

        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? '8px' : null }
          },
          children: (
            <div style={{display: 'flex',justifyContent: 'center'}}>
              {rowData.woN === '' ? '' : text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          )
        };
      },
    },
    {
      title: "剩餘產量",
      dataIndex: "lastInC",
      key: "lastInC",
      className: 'lineHeight',
      align: 'center',
      render: (text, rowData, index) => {

        const color = text < 0 ? '#f0948ecf' : text < 50 ? '#f5d10396' : ''
        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? '8px' : null }
          },
          children: (
            <div style={{display: 'flex',justifyContent: 'center', backgroundColor: color}}>
              {rowData.woN === '' ? '' : text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          )
        };
      },
      // width: 160,
      width: innerWidth <= 1440 ? `${180}px` : `${100}px`
    },
    {
      // title: "產品週期",
      title: <span>產品<br />週期</span>,
      width: innerWidth <= 1440 ? `${60}px` : `${80}px`,
      align: 'center',
      className: 'lineHeight',
      dataIndex: "cycletimemh",
      key: "cycletimemh",
      render: (text, rowData, index) => {
        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? '8px' : null }
          },
          children: (
            <div style={{display: 'flex',justifyContent: 'center'}}>
              {rowData.woN === '' ? '' : text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          )
        };
      },
    },
    // {
    //   //title: "投產數量",
    //   title: <span>投產<br />數量</span>,
    //   width: innerWidth <= 1440 ? `${60}px` : `${80}px`,
    //   align: 'center',
    //   className: 'lineHeight',
    //   dataIndex: "inC",
    //   key: "inC",
    //   render: (text, rowData, index) => {
    //     return {
    //       props: {
    //         style: { fontSize: innerWidth <= 1440 ? '8px' : null }
    //       },
    //       children: (
    //         <div style={{display: 'flex',justifyContent: 'center'}}>
    //           {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
    //         </div>
    //       )
    //     };
    //   },
    // },
    
    // {
    //   title: <span>良品<br />數量</span>,
    //   width: innerWidth <= 1440 ? `${60}px` : `${80}px`,
    //   align: 'center',
    //   className: 'lineHeight',
    //   dataIndex: "okC",
    //   key: "okC",
    //   render: (text, rowData, index) => {
    //     return {
    //       props: {
    //         style: { fontSize: innerWidth <= 1440 ? '8px' : null }
    //       },
    //       children: (
    //         <div style={{display: 'flex',justifyContent: 'center'}}>
    //           {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
    //         </div>
    //       )
    //     };
    //   },
    // },
    // {
    //   //title: "投產數量",
    //   title: <span>不良<br />數量</span>,
    //   width: innerWidth <= 1440 ? `${60}px` : `${80}px`,
    //   align: 'center',
    //   className: 'lineHeight',
    //   dataIndex: "ngC",
    //   key: "ngC",
    //   render: (text, rowData, index) => {
    //     return {
    //       props: {
    //         style: { fontSize: innerWidth <= 1440 ? '8px' : null }
    //       },
    //       children: (
    //         <div style={{display: 'flex',justifyContent: 'center'}}>
    //           {text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
    //         </div>
    //       )
    //     };
    //   },
    // },
    // {
    //   title: "前拆量",
    //   width: 100,
    //   align: 'center',
    //   className: 'lineHeight',
    //   dataIndex: "d_planqty",
    //   key: "d_planqty",
    // },
    // {
    //   title: "後拆量",
    //   width: 100,
    //   align: 'center',
    //   className: 'lineHeight',
    //   dataIndex: "d_inC",
    //   key: "d_inC",
    // },
    {
      // title: "模具穴數",
      title: <span>模具<br />穴數</span>,
      width: innerWidth <= 1440 ? `${60}px` : `${80}px`,
      align: 'center',
      className: 'lineHeight',
      dataIndex: "moldcavity",
      key: "moldcavity",
      render: (text, rowData, index) => {
        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? '8px' : null }
          },
          children: (
            <div style={{display: 'flex',justifyContent: 'center'}}>
              {rowData.woN === '' ? '' : text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          )
        };
      },
    },

    {
      // title: "已打模次",
      title: <span>已打<br />模次</span>,
      width: innerWidth <= 1440 ? `${60}px` : `${80}px`,
      align: 'center',
      className: 'lineHeight',
      dataIndex: "pdtC",
      key: "pdtC",
      render: (text, rowData, index) => {
        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? '8px' : null }
          },
          children: (
            <div style={{display: 'flex',justifyContent: 'center'}}>
              {rowData.woN === '' ? '' : text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          )
        };
      },
    },
    {
      //title: "投產數量",
      title: <span>投產<br />數量</span>,
      width: innerWidth <= 1440 ? `${60}px` : `${80}px`,
      align: 'center',
      className: 'lineHeight',
      dataIndex: "inC",
      key: "inC",
      render: (text, rowData, index) => {
        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? '8px' : null }
          },
          children: (
            <div style={{display: 'flex',justifyContent: 'center'}}>
              {rowData.woN === '' ? '' : text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          )
        };
      },
    },
    {
      //title: "報工時間",
      title: <span>報工<br/>時間</span>,
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
      align: 'center',
      className: 'lineHeight',
      dataIndex: "newprepareT",
      key: "newprepareT",
      render: (text, rowData, index) => {

        const data = text.split(' ');

        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? '8px' : null }
          },
          children: innerWidth <= 1440 ? <span>{data[0]}<br />{data[1]}</span> : text
        };
      },
    },
    {
      //title: "在線人數",
      title: <span>在線<br/>人數</span>,
      width: innerWidth <= 1440 ? `${50}px` : `${100}px`,
      align: 'center',
      className: 'lineHeight',
      dataIndex: "hdC",
      key: "hdC",
      render: (text, rowData, index) => {
        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? '8px' : null }
          },
          children: (
            <div style={{display: 'flex',justifyContent: 'center'}}>
              {rowData.woN === '' ? '' : text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          )
        };
      },
    },
    
    // {
    //   title: <span>完工<br/>時間</span>,
    //   dataIndex: "newcloseT",
    //   width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    //   className: 'lineHeight',
    //   align: 'center',
    //   key: "newcloseT",
    //   render: (text, rowData, index) => {

    //     const data = text.split(' ');

    //     return {
    //       props: {
    //         style: { fontSize: innerWidth <= 1440 ? '8px' : null }
    //       },
    //       children: innerWidth <= 1440 ? <span>{data[0]}<br />{data[1]}</span> : text
    //     };
    //   },
    // },
    
    {
      //title: "累計機時",
      title: <span>累計<br/>機時</span>,
      width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
      className: 'lineHeight',
      align: 'center',
      dataIndex: "emhC",
      key: "emhC",
      render: (text, rowData, index) => {
        return {
          props: {
            style: { textAlign: 'right', paddingRight: '15px', fontSize: innerWidth <= 1440 ? '8px' : null }
          },
          children: (
            <div>
              {rowData.woN === '' ? '' : `${text.toFixed(1)} 小時`}
            </div>
          )
        };
      },
    },
    {
      title: <span>生產<br />效率</span>,
      width: innerWidth <= 1440 ? `${80}px` : `${80}px`,
      align: 'center',
      className: 'lineHeight',
      dataIndex: "ePPE",
      key: "ePPE",
      render: (text, rowData, index) => {
        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? '8px' : null }
          },
          children: (
            <div>
              {rowData.woN === '' ? '' : `${text.toFixed(1)}%`}
            </div>
          )
        };
      },
    },
    // {
    //   //title: "途單歷程",
    //   title: <span>途單<br/>歷程</span>,
    //   width: innerWidth <= 1440 ? `${80}px` : `${100}px`,
    //   align: 'center',
    //   className: 'lineHeight',
    //   dataIndex: "mappedLifeF",
    //   key: "mappedLifeF",
    //   render: (text, rowData, index) => {
    //     return {
    //       props: {
    //         style: { fontSize: innerWidth <= 1440 ? '8px' : null }
    //       },
    //       children: text
    //     };
    //   },
    // },
    {
      // title: '累計達成率',
      title: <span>累計<br />達成率</span>,
      width: innerWidth <= 1440 ? `${80}px` : `${80}px`,
      align: 'center',
      className: 'lineHeight',
      dataIndex: "allPdtRate",
      key: "allPdtRate",
      render: (text, rowData, index) => {
        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? '8px' : null }
          },
          children: (
            <div>
              {rowData.woN === '' ? '' : text}
            </div>
          )
        };
      },
    },
    {
      //title: "歷程時間",
      title:<span>歷程<br/>時間</span>,
      width: innerWidth <= 1440 ? `${70}px` : `${100}px`,
      align: 'center',
      className: 'lineHeight',
      dataIndex: "newlifeFT",
      key: "newlifeFT",
      render: (text, rowData, index) => {

        const data = text.split(' ');

        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? '8px' : null }
          },
          children: rowData.woN !== '' ? (
            innerWidth <= 1440 ? <span>{data[0]}<br />{data[1]}</span> : text
          ) : null
        };
      },
      // render: (text, rowData, index) => {
      //   const date = rowData.newlifeFT.split(' ');
        
      //   return <span>{date[0]}<br />{date[1]}</span>
      // }
    },
    {
      //title: "歷程時間",
      title:<span>更新<br/>時間</span>,
      width: innerWidth <= 1440 ? `${70}px` : `${100}px`,
      align: 'center',
      className: 'lineHeight',
      dataIndex: "newsliceT",
      key: "newsliceT",
      render: (text, rowData, index) => {

        const data = text.split(' ');

        return {
          props: {
            style: { fontSize: innerWidth <= 1440 ? '8px' : null }
          },
          children: innerWidth <= 1440 ? <span>{data[0]}<br />{data[1]}</span> : text
        };
      },
      // render: (text, rowData, index) => {
      //   const date = rowData.newlifeFT.split(' ');
        
      //   return <span>{date[0]}<br />{date[1]}</span>
      // }
    },
    // {
    //   title: <span>切片<br/>時間</span>,
    //   width: 100,
    //   dataIndex: "sliceT",
    //   key: "sliceT",
    // },
  ];
  const getDate = date => {
    const month = '' + (date.getMonth() + 1);
    const day = '' + date.getDate();
    const year = date.getFullYear();
    console.log(month, day)
  }
  const showLight = (color) => {
    console.log(color)
    const style = {
      width: '20px', 
      height: '20px', 
      backgroundColor: color,
      display: "inline-block",
      borderRadius: "50%",
      border: "0.1px solid #E0E0E0",
      verticalAlign: "middle",
    }
    return (
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div style={style}/>
      </div>
    );
  };

  return (
    <>
      {/* <MyMainColorH1>數位生產看板</MyMainColorH1> */}
      <Title title='數位生產看板'>
        <div style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'flex-end'}}>
          <DashBox header='開線率' value={Object.keys(s_boxData).length > 0 ? s_boxData.openLineRate : ''}></DashBox>
          <DashBox header='開線數' value={Object.keys(s_boxData).length > 0 ? s_boxData.openLine : ''} />
          <DashBox header='總線數' value={Object.keys(s_boxData).length > 0 ? s_boxData.allLine : ''}></DashBox>
          <Select 
            style={{ width: '150px'}} 
            size='large'
            value={s_psID}
            onChange={value => handleChange(value)}
          >
            {s_AllStation_options}
          </Select>
          {/* <Button 之後做
            onClick={() => set_s_showModal(true)} 
            type="primary" 
            style={{borderRadius: '5px'}} 
            icon={<PlusOutlined />} 
            size='large'
          >
            新增
          </Button> */}
        </div>
      </Title>
      {/* <Row justify="center" align="middle">
        <Col span={24}> */}
          <CustomTable
            scroll={{ x: 'max-content',y: window.innerHeight - 60 - 45 - 180 }}
            pagination={false}
            // bordered={true}
            size={innerWidth <= 1440 ? '14px' : null}
            columns={tableColumns}
            dataSource={tableData.map((x, index) => {
              return { ...x, key: index };
            })}
          />
        {/* </Col>
      </Row> */}
      {s_showModal ? (
        <CustomModal 
          width='30%' 
          title='警示設定' 
          visible={s_showModal} 
          maskClosable={false}
          destroyOnClose
          footer={null}
          forceRender
          getContainer={false}
          onCancel={() => set_s_showModal(false)}
        >
          <ModalContainer set_s_showModal={set_s_showModal} />
        </CustomModal>
      ) : null}
    </>
  );
}

export default DashBoard;
