import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {MyFooter, MyH4, MyH3}  from '../../styled/publicTag';
import Logo from '../../assets/images/logo.png'
import Lamp from '../../assets/images/lamp.png'
import { useLocation } from "react-router-dom";

import {Space} from 'antd'

const Box = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 100%;
  margin-right: 5px;
  background-color: ${props => props.color ? props.color : null};
  border: ${props => props.color === 'white' ? '1px solid #CCC' : ''};
`

const Footer = () =>{

  const { pathname } = useLocation(); // 三大表不要出現 footer
  const [s_isShowFooter, set_s_isShowFooter] = useState(true);

  const imgStyle = {
    height: '90%',
  }
  const lampStyle = {
    height: '25px',
  }

  //三大表不要出現footer
  useEffect(() => {
    const arrayMap = ['/dashboard', '/abcboard'];
    arrayMap.includes(pathname) ? set_s_isShowFooter(true) : set_s_isShowFooter(false)
  }, [pathname])

	return pathname === '/abcboard' ? (
    <>
      <Space size={[16, 0]} >
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Box color='rgb(54, 230, 28)' style={lampStyle}/>
          <MyH4>人工解除</MyH4>
        </div>
        
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Box color='rgb(58, 71, 78)'/>
          <MyH4>系統解除</MyH4>
        </div>
        
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Box color='red'/>
          <MyH4>異常停工</MyH4>
        </div>
        
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Box color='yellow'/>
          <MyH4>異常提報</MyH4>
        </div>
        
      </Space>
    </>
  ) : pathname === '/dashboard' ? (
    <>
      <Space size={[16, 0]} >
        <div style={{display: 'flex', alignItems: 'center', marginRight: '10px'}}>
          <Box color='white'/>
          <MyH4>待機中</MyH4>
        </div>
        
        <div style={{display: 'flex', alignItems: 'center', marginRight: '10px'}}>
          <Box color='yellow'/>
          <MyH4>異常提報</MyH4>
        </div>
        
        <div style={{display: 'flex', alignItems: 'center', marginRight: '10px'}}>
          <Box color='red'/>
          <MyH4>異常停工</MyH4>
        </div>
        
        <div style={{display: 'flex', alignItems: 'center', marginRight: '10px'}}>
          <Box color='rgb(54, 230, 28)'/>
          <MyH4>正常生產</MyH4>
        </div>
        
        <div style={{display: 'flex', alignItems: 'center', marginRight: '10px'}}>
          <Box color='blue'/>
          <MyH4>上模中</MyH4>
        </div>
        
      </Space>
    </>
  ) : null
}
export default Footer